import React, { useState, useEffect } from "react";
import { Prompt, Route, Switch, Link, useRouteMatch, useParams } from "react-router-dom";
import { Row, Panel, Table, Input, Toggle, Select, Button } from "@telosalliance/ui-core";
import { alert as Alert, confirm as Confirm } from "@telosalliance/ui-core-framework"
import { RequestAPI, RequestMethods, Breadcrumb, Notification, Warnings, LoadIndicator, InfoTooltip, _mergeArray, IsValidLwChannel, IsValidIPv4MulticastP } from '../Utils';
import { merge as _merge, cloneDeep as _clone } from 'lodash';
import { StreamPicker } from '../StreamPicker';

const apiUrl = '/studios';
const addressbookUrl = 'addressbook';
const addPath = 'add-studio';

const StudiosPage = ({ history, sitePadding, helpContext, warnings }) => {
  const LabelWidth = '250px';
  const Warning = { variant: 'warning' }; //Alert, Confirm
  const UnsavedMessage = 'Studio data has been changed but haven\'t been saved. Continue?';

  const ChannelTypeStandardStereo = 'STD_STEREO';
  const ChannelTypeAES67Multicast = 'LOW_LATENCY';
  const ChannelTypes = [
    //{ id: 'LIVE_STEREO', name: 'Live Stereo' },
    { id: ChannelTypeStandardStereo, name: 'Standard Stereo' },
    { id: ChannelTypeAES67Multicast, name: 'AES67 Multicast' }
  ];
  /*const SourceTypes = [
    { id: null, name: '' },
    { id: 'LW_FROM_SRC', name: 'From Source' },
    { id: 'LW_TO_SRC', name: 'To Source' },
    { id: 'AES_MULTICAST', name: 'AES67 Multicast' }
  ];*/
  const GPIOActions = [
    { id: 'none', name: '----- NONE -----'},
    { id: 'next', name: 'Take next call'},
    { id: 'ringing', name: 'Take next ringing line'},
    { id: 'hold', name: 'Hold all calls'},
    { id: 'drop', name: 'Drop all calls'},
    { id: 'busy_all_on', name: 'Enable Block All'},
    { id: 'busy_all_off', name: 'Disable Block All'},
    { id: 'busy_all', name: 'Toggle Block All'},
    { id: 'auto_answer', name: 'Toggle Auto Answer & Hold'},
    { id: 'mute', name: 'Mute Ringer'}
  ];
  const GPIOIndications = [
    { id: 'none', name: '----- NONE -----'},
    { id: 'next', name: 'Next call available'},
    { id: 'ringing', name: 'Line ringing'},
    { id: 'ringing_busy', name: 'Line ringing ("Busy All")'},
    { id: 'ringing_non_busy', name: 'Line ringing (non-"Busy All")'},
    { id: 'hold', name: 'Call can be held'},
    { id: 'drop', name: 'Call can be dropped'},
    { id: 'busy_all', name: 'Block All enabled'},
    { id: 'auto_answer', name: 'Auto Answer & Hold enabled'},
    { id: 'mute', name: 'Ringer muted'},
    { id: 'delay_dump', name: 'Delay Dump'}
  ];
  const GPIOPinTypes = [
    { id: '1', name: 'Pin 1' },
    { id: '2', name: 'Pin 2' },
    { id: '3', name: 'Pin 3' },
    { id: '4', name: 'Pin 4' },
    { id: '5', name: 'Pin 5' }
  ];
  const GPIOModes = [
    { id: 'false', name: 'From GPIO' },
    { id: 'true', name: 'To GPIO' }
  ];
  const nullAddress = "0.0.0.0";

  const { path } = useRouteMatch();

  function RenderStudios() {
    const [loading, setLoading] = useState(false);
    const [studios, setStudios] = useState([]);

    function refreshStudios() {
      RequestAPI(apiUrl, null, null, data => {
        setLoading(false);
        setStudios(data);
      });
    }

    function deleteStudio(id) {
      setLoading(true);
      RequestAPI(apiUrl + '/' + id, null, RequestMethods.DELETE, () => { refreshStudios(); });
    }

    useEffect(() => {
      setLoading(true);
      refreshStudios();
    }, []);

    return (<>
      <LoadIndicator open={loading}/>
      <Breadcrumb item="Studios Configuration"/>
      <Warnings value={warnings}/>

      <h1>Studios Configuration</h1>
      <br />
      <Row spacing={sitePadding}>        
        <Panel title="Studios">
          <Table
            alignLabelsLeft={true}
            headers={[
              <>Studio Name<InfoTooltip source={helpContext} path="studios/studios-name"/></>,
              <>Lines<InfoTooltip source={helpContext} path="studios/studios-lines"/></>,
              <>Fixed Channels<InfoTooltip source={helpContext} path="studios/studios-chfix"/></>,
              <>Selectable Channels<InfoTooltip source={helpContext} path="studios/studios-chsel"/></>,
              <>Active Show<InfoTooltip source={helpContext} path="studios/studios-show"/></>,
              ""]}
            rows={studios.map((studio) => [
              studio.name,
              studio.num_lines,
              studio.nfix,
              studio.nsel,
              <>{ studio.showid ? <>{studio.show}</> : 'No show' }</>,
              <>
                <Link to={'/studios/' + studio.id}><Button>Edit</Button></Link>&nbsp;
                <Button disabled={studio.showid} onClick={ async () => {
                  if (await Confirm('Deleting studio "' + studio.name + '" cannot be undone. Continue?', Warning)) deleteStudio(studio.id);
                }}>Delete</Button>
              </>
            ]).concat([[
              <></>,
              <></>,
              <></>,
              <></>,
              <Link to={path + '/' + addPath}><Button color="blue">Add</Button></Link>
            ]])} />
        </Panel>
      </Row>

    </>);
  }

  function RenderStudioEditor() {
    const blankStudio = {
      add: true,
      show: null,
      shows: [],
      studio: {
        name: '',
        num_lines: 0,
        ch_fix: [],
        ch_sel: []
      },
      phold: {},
      aec: null,
      gpio: { actions: [], indications: [] },
      disable_show: false,
      disable_aec: false
    };

    const [loading, setLoading] = useState(false);
    const [unsaved, setUnsaved] = useState(false);
    const [editor, setEditor] = useState({licenses: {num_faders: 0, max_faders: 0, exceeded: false}, channels: 0, data: blankStudio});

    function getStudioID() { return editor.data.studio.id; }

    function editStudio(id) {
      RequestAPI('/features', null, null, licenses => {
        licenses.exceeded = licenses.num_faders > licenses.max_faders;

        RequestAPI(apiUrl, null, null, studios => {
          if (id === addPath) {
            let r = /^Studio ([0-9]+)$/, m = 0;
            studios.map(studio => {
              let n = studio.name && studio.name.match(r);
              if (n && Number(n[1]) > m) m = Number(n[1]);
              return null;
            });

            setLoading(false);
            let max_aec = Math.min(1, Math.max(0, licenses.max_aec - licenses.num_aec));
            let disable_aec = licenses.num_aec >= licenses.max_aec;
            let limit_lines = Math.max(0, licenses.max_lines - licenses.num_lines);
            setEditor({licenses: licenses, channels: 0, limit_lines: limit_lines, data: _mergeArray({}, blankStudio,
              {studio: {name: 'Studio ' + (Number(m) + 1), num_lines: limit_lines, max_aec: max_aec}, disable_aec: disable_aec}
            )});
          } else {
            let found = false;
            studios.map((studio) => {
              if (studio.id === Number(id)) found = true;
              return false;
            });

            if (found) RequestAPI(apiUrl + '/' + id, null, null, (data) => {
              setLoading(false);
              data.showid = data.show;
              let limit_lines = data.disable_show ? data.studio.num_lines : Math.max(0, licenses.max_lines - licenses.num_lines + data.studio.num_lines);

              const isAdvanced = (ch) => {
                if (ch.send.mode === ChannelTypeAES67Multicast) return true;

                const sendStreams = String(ch.send.stream).split(',');
                const recvStreams = String(ch.recv.stream).split(',');
                return (IsValidLwChannel(sendStreams[0]) && Number(sendStreams[0]) !== -Number(recvStreams[0]) && Number(sendStreams[0]) !== -Number(recvStreams[1])) ||
                       (IsValidLwChannel(sendStreams[1]) && Number(sendStreams[1]) !== -Number(recvStreams[1]) && Number(sendStreams[1]) !== -Number(recvStreams[0])) ||
                       IsValidIPv4MulticastP(sendStreams[0]) || IsValidIPv4MulticastP(sendStreams[1]) ||
                       IsValidIPv4MulticastP(recvStreams[0]) || IsValidIPv4MulticastP(recvStreams[1]);
              }

              data.studio.ch_fix.map(ch => { ch.recv.advanced = isAdvanced(ch); return null; });
              data.studio.ch_sel.map(ch => { ch.recv.advanced = isAdvanced(ch); return null; });

              setEditor({licenses: licenses, channels: data.studio.ch_fix.length + data.studio.ch_sel.length, limit_lines: limit_lines, data: data});
            }); else history.replace(path);
          }
        });
      });
    }
    function updateStudio(value, set = true) {
      setEditor(_merge({}, editor, { data: value }));
      if (set) setUnsaved(true);
    }
    function verifyUnsaved() { return (unsaved && !window.confirm(UnsavedMessage)) === false; }
    function saveStudioShow(e) {
      e.preventDefault();

      if (!verifyUnsaved()) return;
      setUnsaved(false);

      setLoading(true);
      let id = getStudioID();
      RequestAPI(apiUrl + '/' + id + '/show', { id: editor.data.showid }, RequestMethods.POST, () => { editStudio(id); });
    }

    function verifyStudioChannelLicenses(){
      let channels = editor.data.studio.ch_fix.filter(ch => !ch.remove).length +
                     editor.data.studio.ch_sel.filter(ch => !ch.remove).length;

      if (editor.licenses.num_faders - editor.channels + channels >= editor.licenses.max_faders) {
        Alert('Cannot add channel! License count exceeded.', Warning);
        return false;
      }
      return true;
    }
    function updateStudioFixedChannel(index, value) {
      let ch_fix = _clone(editor.data.studio.ch_fix);
      ch_fix[index] = _merge(ch_fix[index], value);
      setEditor(_mergeArray({}, editor, { data: { studio: { ch_fix: ch_fix }}}));
      setUnsaved(true);
    }
    function addStudioFixedChannel() {
      if (!verifyStudioChannelLicenses()) return;

      let r = /^.*:Fix ([0-9]+)$/, m = 0;
      editor.data.studio.ch_fix.map(ch => {
        let n = ch.name && ch.name.match(r);
        if (n && Number(n[1]) > m) m = Number(n[1]);
        return null;
      });
      let ch_fix = _clone(editor.data.studio.ch_fix);
      ch_fix.push({add: true, name: editor.data.studio.name + ':Fix ' + (Number(m) + 1), send: {}, recv: {stream: 'auto', advanced: false}});
      setEditor(_mergeArray({}, editor, { data: { studio: { ch_fix: ch_fix }}}));
      setUnsaved(true);
    }
    function deleteStudioFixedChannel(index) {
      let ch_fix = _clone(editor.data.studio.ch_fix);
      if (ch_fix[index].add) ch_fix.splice(index, 1); else ch_fix[index].remove = true;
      setEditor(_mergeArray({}, editor, { data: { studio: { ch_fix: ch_fix }}}));
      setUnsaved(true);
    }
    function formatStudioFixedChannel(index) {
      let ch_fix = _clone(editor.data.studio.ch_fix);
      let streams = String(ch_fix[index].send.stream).trim().split(',');
      if (streams.length > 1) {
        if (ch_fix[index].send.mode === ChannelTypeStandardStereo) streams = streams.map(s => s ? s : '0');
        else streams = streams.map(s => s ? s : nullAddress);

        let stream = streams.splice(0, 2).join(',');
        if (stream === '0,0') stream = '0';
        if (stream === nullAddress + ',' + nullAddress) stream = nullAddress;

        updateStudioFixedChannel(index, {send: {stream: stream}});
      }
    }

    function updateStudioSelectableChannel(index, value) {
      let ch_sel = _clone(editor.data.studio.ch_sel);
      ch_sel[index] = _merge(ch_sel[index], value);
      setEditor(_mergeArray({}, editor, { data: { studio: { ch_sel: ch_sel }}}));
      setUnsaved(true);
    }
    function addStudioSelectableChannel() {
      if (!verifyStudioChannelLicenses()) return;

      let r = /^.*:Sel ([0-9]+)$/, m = 0;
      editor.data.studio.ch_sel.map(ch => {
        let n = ch.name && ch.name.match(r);
        if (n && Number(n[1]) > m) m = Number(n[1]);
        return null;
      });
      let ch_sel = _clone(editor.data.studio.ch_sel);
      ch_sel.push({add: true, name: editor.data.studio.name + ':Sel ' + (Number(m) + 1), send: {}, recv: {stream: 'auto', advanced: false}});
      setEditor(_mergeArray({}, editor, { data: { studio: { ch_sel: ch_sel }}}));
      setUnsaved(true);
    }
    function deleteStudioSelectableChannel(index) {
      let ch_sel = _clone(editor.data.studio.ch_sel);
      if (ch_sel[index].add) ch_sel.splice(index, 1); else ch_sel[index].remove = true;
      setEditor(_mergeArray({}, editor, { data: { studio: { ch_sel: ch_sel }}}));
      setUnsaved(true);
    }
    function formatStudioSelectableChannel(index) {
      let ch_sel = _clone(editor.data.studio.ch_sel);
      let streams = String(ch_sel[index].send.stream).trim().split(',');
      if (streams.length > 1) {
        if (ch_sel[index].send.mode === ChannelTypeStandardStereo) streams = streams.map(s => s ? s : '0');
        else streams = streams.map(s => s ? s : nullAddress);

        let stream = streams.splice(0, 2).join(',');
        if (stream === '0,0') stream = '0';
        if (stream === nullAddress + ',' + nullAddress) stream = nullAddress;

        updateStudioSelectableChannel(index, {send: {stream: stream}});
      }
    }

    function updateStudioGPIOAction(index, value) {
      let actions = _clone(editor.data.gpio.actions);
      actions[index] = _merge(actions[index], value);
      setEditor(_mergeArray({}, editor, { data: { gpio: { actions: actions }}}));
      setUnsaved(true);
    }
    function addStudioGPIOAction() {
      let actions = _clone(editor.data.gpio.actions);
      actions.push({add: true, action: 'next', tosrc: false, pin: 1});
      setEditor(_mergeArray({}, editor, { data: { gpio: { actions: actions }}}));
      setUnsaved(true);
    }
    function deleteStudioGPIOAction(index) {
      let actions = _clone(editor.data.gpio.actions);
      if (actions[index].add) actions.splice(index, 1); else actions[index].remove = true;
      setEditor(_mergeArray({}, editor, { data: { gpio: { actions: actions }}}));
      setUnsaved(true);
    }

    function updateStudioGPIOIndication(index, value) {
      let indications = _clone(editor.data.gpio.indications);
      indications[index] = _merge(indications[index], value);
      setEditor(_mergeArray({}, editor, { data: { gpio: { indications: indications }}}));
      setUnsaved(true);
    }
    function addStudioGPIOIndication() {
      let indications = _clone(editor.data.gpio.indications);
      indications.push({add: true, action: 'next', tosrc: false, pin: 1});
      setEditor(_mergeArray({}, editor, { data: { gpio: { indications: indications }}}));
      setUnsaved(true);
    }
    function deleteStudioGPIOIndication(index) {
      let indications = _clone(editor.data.gpio.indications);
      if (indications[index].add) indications.splice(index, 1); else indications[index].remove = true;
      setEditor(_mergeArray({}, editor, { data: { gpio: { indications: indications }}}));
      setUnsaved(true);
    }

    function saveStudio(e) {
      e.preventDefault();

      if (editor.data.studio.name === addPath) {
        Alert('Studio name "' + editor.data.studio.name + '" cannot be used! Please choose a different name.', Warning);
        return;
      }

      setUnsaved(false);

      let GPIOActions = editor.data.gpio.actions.map((gpi, index) => _merge(gpi, {index: index + 1}));
      let addGPIOActions = GPIOActions.filter(gpi => gpi.add);
      let removeGPIOActions = GPIOActions.filter(gpi => gpi.remove);
      let GPIOIndications = editor.data.gpio.indications.map((gpo, index) => _merge(gpo, {index: index + 1}));
      let addGPIOIndications = GPIOIndications.filter(gpo => gpo.add);
      let removeGPIOIndications = GPIOIndications.filter(gpo => gpo.remove);

      let next = (callback) => save(id, addGPIOActions, removeGPIOActions, addGPIOIndications, removeGPIOIndications, callback);
      let save = (id, addGPIOActions, removeGPIOActions, addGPIOIndications, removeGPIOIndications, callback) => {
        if (addGPIOActions.length) {
          let gpi = addGPIOActions.shift();
          RequestAPI(apiUrl + '/' + id + '/gpio/actions', gpi, RequestMethods.POST, () => { next(callback); });
        } else if (removeGPIOActions.length) {
          let gpi = removeGPIOActions.shift();
          RequestAPI(apiUrl + '/' + id + '/gpio/actions/' + gpi.index, null, RequestMethods.DELETE, () => { next(callback); });
        } else if (addGPIOIndications.length) {
          let gpo = addGPIOIndications.shift();
          RequestAPI(apiUrl + '/' + id + '/gpio/indications', gpo, RequestMethods.POST, () => { next(callback); });
        } else if (removeGPIOIndications.length) {
          let gpo = removeGPIOIndications.shift();
          RequestAPI(apiUrl + '/' + id + '/gpio/indications/' + gpo.index, null, RequestMethods.DELETE, () => { next(callback); });
        } else callback();
      }

      editor.data.studio.ch_fix = editor.data.studio.ch_fix.filter(ch => !ch.remove);
      editor.data.studio.ch_sel = editor.data.studio.ch_sel.filter(ch => !ch.remove);

      editor.data.studio.ch_fix.map(ch => { if (!ch.recv.advanced) { ch.recv.stream = 'auto'; } return null; });
      editor.data.studio.ch_sel.map(ch => { if (!ch.recv.advanced) { ch.recv.stream = 'auto'; } return null; });

      if (editor.data.studio.ch_fix.length + editor.data.studio.ch_sel.length === 0) {
        Alert('Studio requires at least one fixed or selectable channel.', Warning);
        return;
      }

      setLoading(true);
      let id = getStudioID();
      if (editor.data.add) {
        RequestAPI(apiUrl, editor.data, RequestMethods.POST, () => {
          RequestAPI(apiUrl, null, RequestMethods.GET, (studios) => {
            studios = studios.filter(studio => studio.name === editor.data.studio.name);
            if (studios.length) {
              id = studios[0].id;
              save(id, addGPIOActions, removeGPIOActions, addGPIOIndications, removeGPIOIndications, () => {
                history.replace(path + '/' + id);
                editStudio(id);
              });
            } else {
              setLoading(false);
              Alert('Cannot create studio "' + editor.data.studio.name + '"!', Warning);
            }
          });
        });
      } else {
        RequestAPI(apiUrl + '/' + id, editor.data, RequestMethods.POST, () => {
          save(id, addGPIOActions, removeGPIOActions, addGPIOIndications, removeGPIOIndications, () => editStudio(id));
        });
      }
    }

    let { id } = useParams();
    useEffect(() => {
      setLoading(true);
      editStudio(id);
    }, [id]);

    return (<>
      <LoadIndicator open={loading}/>
      <Breadcrumb item="Studio" path={[
        { link: '/studios', text: 'Studios Configuration' }
      ]}/>
      <Warnings value={warnings}/>

      <h1>{editor.data.add !== true ? <>Studio "{editor.data.studio.name}"</> : <>Add Studio</>}</h1>
      <br />
      <Row spacing={sitePadding}>
        {editor.data.add !== true ?
          <Panel title="Current Show" className="currentshow">
            <form onSubmit={saveStudioShow}>
              <Table
                columnWidths={[LabelWidth]}
                rows={[
                  ["Change Show",
                    <>
                      <Select value={editor.data.showid} onChange={(value) => { updateStudio({showid: value}, false); }}>
                        {[{id: "0", name: "No Show", enabled: true}].concat(editor.data.shows).map(({id, name, enabled}) => <option value={id} disabled={enabled ? null : editor.data.disable_show}>{name}</option>)}
                      </Select>
                      <InfoTooltip source={helpContext} path="studios/change-show"/>
                    </>
                  ],
                ]}/>
              <div className="btn-row">
                <Button color="blue" type="submit">Save</Button>
              </div>
            </form>
          </Panel>
        : <></> }
        <Panel title="Studio">
          <Notification visible={editor.data.show !== null}>Studio is using show "{editor.data.shows.map(show => show.id === editor.data.show ? show.name : '')}"<InfoTooltip source={helpContext} path="studios/in-use"/></Notification>
          <Notification visible={editor.licenses.exceeded}>Studio cannot be edited! Global channel limit exceeded!</Notification>
          <Notification visible={editor.data.disable_show}>Global line limit exceeded!</Notification>
          <Prompt when={unsaved} message={UnsavedMessage}/>

          <form onSubmit={saveStudio}>
            <h3>Configuration</h3>
            <Table
              columnWidths={[LabelWidth]}
              rows={[
                ["Studio Name", <><Input autoFocus disabled={editor.licenses.exceeded || editor.data.show} value={editor.data.studio.name} onChange={(value) => { updateStudio({studio: {name: value}}); }}/><InfoTooltip source={helpContext} path="studios/studio-name"/></>],
                ["Lines", <><input className="uic-input" disabled={editor.licenses.exceeded || editor.data.show} value={editor.data.studio.num_lines} type="number" min="0" max={editor.limit_lines} onChange={(e) => { updateStudio({studio: {num_lines: Number(e.currentTarget.value)}}); }}/><InfoTooltip source={helpContext} path="studios/studio-num-lines"/></>],
                ["Auto Answer Fixed Lines", <><Toggle disabled={editor.licenses.exceeded || editor.data.show} checked={editor.data.studio.autoanswerfix} onChange={(value) => { updateStudio({studio: { autoanswerfix: value}}); }} /><InfoTooltip source={helpContext} path="studios/studio-autoanswer-fixed"/></>],
                ["Lockless Conferencing", <><Toggle disabled={editor.licenses.exceeded || editor.data.show} checked={editor.data.studio.lockless} onChange={(value) => { updateStudio({studio: {lockless: value}}); }} /><InfoTooltip source={helpContext} path="studios/studio-lockless"/></>],
              ]}/>
            <br/>
            <h3>Fixed Channels</h3>
            <Table
              columnWidths={[LabelWidth]} //TODO
              headers={[
                "",
                <>Enabled<InfoTooltip source={helpContext} path="studios/studio-ch-enabled"/></>,
                <>Display Name<InfoTooltip source={helpContext} path="studios/studio-ch-name"/></>,
                <>Output<InfoTooltip source={helpContext} path="studios/studio-ch-output"/></>,
                <>Manual Backfeed / Input<InfoTooltip source={helpContext} path="studios/studio-ch-input"/></>,
                ""]}
              rows={editor.data.studio.ch_fix.map((ch, index) => {
                if (ch.remove) return [];
                
                return [
                  <></>,
                  <Toggle disabled={editor.licenses.exceeded} checked={!ch.disabled} onChange={(value) => { updateStudioFixedChannel(index, { disabled: !value}); }} />,
                  <Input disabled={editor.licenses.exceeded} value={ch.name} onChange={(value) => { updateStudioFixedChannel(index, {name: value}); }}/>,
                  <nobr>
                    <Input disabled={editor.licenses.exceeded} value={ch.send.stream}
                      onChange={(value) => { updateStudioFixedChannel(index, {send: {stream: value, mode: ch.send.mode ? ch.send.mode : ChannelTypeStandardStereo}}); }}
                      onBlur={() => { formatStudioFixedChannel(index); }}/>&nbsp;
                    <Select disabled={editor.licenses.exceeded} value={ch.send.mode} onChange={(value) => { updateStudioFixedChannel(index, {send: {mode: value}}); }}>{ChannelTypes.reduce((a, v) => { if (!a.length && !ch.send.channel) a.push({id: null, name: ''}); a.push(v); return a; }, []).map(({id, name}) => <option value={id}>{name}</option>)}</Select>
                  </nobr>,
                  <nobr>
                    <Toggle disabled={editor.licenses.exceeded} checked={ch.recv.advanced} onChange={(value) => { updateStudioFixedChannel(index, {recv: { advanced: value }}); }} />&nbsp;
                    <StreamPicker className="streamPicker" smpte2022_7="true" disabled={editor.licenses.exceeded || !ch.recv.advanced} auto={!ch.recv.advanced} value={!ch.recv.advanced ? {} : ch.recv} onChange={(value) => { updateStudioFixedChannel(index, {recv: value}); }}/>
                  </nobr>,
                  <Button disabled={editor.licenses.exceeded || editor.data.show !== null} onClick={ async () => { if (await Confirm('Delete channel?', Warning)) deleteStudioFixedChannel(index); }}>Delete</Button>
                ]}).concat([[
                  <></>,
                  <></>,
                  <></>,
                  <></>,
                  <></>,
                  <Button disabled={editor.licenses.exceeded || editor.data.show !== null} color="blue" onClick={() => { addStudioFixedChannel(); }}>Add</Button>
              ]])}/>
            <br/>
            <h3>Selectable Channels</h3>
            <Table
              columnWidths={[LabelWidth]}
              headers={[
                "",
                <>Enabled<InfoTooltip source={helpContext} path="studios/studio-ch-enabled"/></>,
                <>Display Name<InfoTooltip source={helpContext} path="studios/studio-ch-name"/></>,
                <>Output<InfoTooltip source={helpContext} path="studios/studio-ch-output"/></>,
                <>Manual Backfeed / Input<InfoTooltip source={helpContext} path="studios/studio-ch-input"/></>,
                ""]}
              rows={editor.data.studio.ch_sel.map((ch, index) => {
                if (ch.remove) return [];
                
                return [
                  <></>,
                  <Toggle disabled={editor.licenses.exceeded} checked={!ch.disabled} onChange={(value) => { updateStudioSelectableChannel(index, { disabled: !value}); }} />,
                  <Input disabled={editor.licenses.exceeded} value={ch.name} onChange={(value) => { updateStudioSelectableChannel(index, {name: value}); }}/>,
                  <nobr>
                    <Input disabled={editor.licenses.exceeded} value={ch.send.stream}
                      onChange={(value) => { updateStudioSelectableChannel(index, {send: {stream: value, mode: ch.send.mode ? ch.send.mode : ChannelTypeStandardStereo}}); }}
                      onBlur={() => { formatStudioSelectableChannel(index); }}/>&nbsp;
                    <Select disabled={editor.licenses.exceeded} value={ch.send.mode} onChange={(value) => { updateStudioSelectableChannel(index, {send: {mode: value}}); }}>{ChannelTypes.reduce((a, v) => { if (!a.length && !ch.send.channel) a.push({id: null, name: ''}); a.push(v); return a; }, []).map(({id, name}) => <option value={id}>{name}</option>)}</Select>
                  </nobr>,
                  <nobr>
                    <Toggle disabled={editor.licenses.exceeded} checked={editor.licenses.exceeded || ch.recv.advanced} onChange={(value) => { updateStudioSelectableChannel(index, {recv: { advanced: value }}); }} />&nbsp;
                    <StreamPicker className="streamPicker" smpte2022_7="true" disabled={editor.licenses.exceeded || !ch.recv.advanced} auto={!ch.recv.advanced} value={!ch.recv.advanced ? {} : ch.recv} onChange={(value) => { updateStudioSelectableChannel(index, {recv: value}); }}/>
                  </nobr>,
                  <Button disabled={editor.licenses.exceeded || editor.data.show !== null} onClick={ async () => { if (await Confirm('Delete channel?', Warning)) deleteStudioSelectableChannel(index); }}>Delete</Button>
              ]}).concat([[
                <></>,
                <></>,
                <></>,
                <></>,
                <></>,
                <Button disabled={editor.licenses.exceeded || editor.data.show !== null} color="blue" onClick={() => { addStudioSelectableChannel(); }}>Add</Button>
              ]])}/>
            <br/>
            <h3>Program On Hold</h3>
            <Table
              columnWidths={[LabelWidth]}
              rows={[
                ["Input Channel", <><StreamPicker className="streamPicker" smpte2022_7="true" disabled={editor.licenses.exceeded} value={editor.data.phold} onChange={(value) => { updateStudio({phold: value}); }}/><InfoTooltip source={helpContext} path="studios/studio-poh-input"/></>],
              ]}/>
            <br/>
            <Notification visible={editor.data.disable_aec}>Acoustic Echo Canceller cannot be configured or edited! License limit exceeded!</Notification>
            <h3>Acoustic Echo Canceller</h3>
            <Table
              columnWidths={[LabelWidth]}
              rows={[
                ["Configured", <><Toggle disabled={editor.licenses.exceeded || !editor.data.studio.max_aec || editor.data.disable_aec} checked={editor.data.aec != null} onChange={(value) => {
                  updateStudio({aec: value ? {enabled: false, recv_mic: {stream: null}, recv_ref: {stream: null}, send: {stream: null}} : null});
                }} /><InfoTooltip source={helpContext} path="studios/studio-aec-enabled"/></>],
              ]}/>
            {editor.data.aec ? <>
              <Table
                columnWidths={[LabelWidth]}
                rows={[
                  ["Enabled", <><Toggle disabled={editor.licenses.exceeded || !editor.data.studio.max_aec || editor.data.disable_aec} checked={editor.data.aec.enabled} onChange={(value) => { updateStudio({aec: { enabled: value}}); }} /><InfoTooltip source={helpContext} path="studios/studio-aec-enabled"/></>],
                ]}/>
              <br/>
              <Table
                columnWidths={[LabelWidth]}
                rows={[
                  ["Mic Input",
                    <>
                      <StreamPicker className="streamPicker" type="output" disabled={editor.licenses.exceeded || !editor.data.aec.enabled || !editor.data.studio.max_aec || editor.data.disable_aec} value={editor.data.aec.recv_mic} onChange={(value) => { updateStudio({aec: {recv_mic: value}}); }}/>
                      <InfoTooltip source={helpContext} path="studios/studio-aec-input"/>
                    </>],
                  ["Reference (CRMON)",
                    <>
                      <StreamPicker className="streamPicker" type="output" disabled={editor.licenses.exceeded || !editor.data.aec.enabled || !editor.data.studio.max_aec || editor.data.disable_aec} value={editor.data.aec.recv_ref} onChange={(value) => { updateStudio({aec: {recv_ref: value}}); }}/>
                      <InfoTooltip source={helpContext} path="studios/studio-aec-reference"/>
                    </>],
                  ["Output (Backfeed)",
                    <nobr>
                      <StreamPicker className="streamPicker" type="output" disabled={editor.licenses.exceeded || !editor.data.aec.enabled || !editor.data.studio.max_aec || editor.data.disable_aec} value={editor.data.aec.send} onChange={(value) => { updateStudio({aec: {send: value}}); }}/>
                      <InfoTooltip source={helpContext} path="studios/studio-aec-output"/>
                    </nobr>],
                ]}/>
            </> : <></>}
            <br/>
            <h3>GPIO Actions</h3>
            <Table
              columnWidths={[LabelWidth].concat(["1px", "1px", "1px", "1px"])} //TODO
              headers={[
                "",
                <>Action<InfoTooltip source={helpContext} path="studios/studio-gpio-action"/></>,
                <>Channel<InfoTooltip source={helpContext} path="studios/studio-gpio-channel"/></>,
                <>Pin<InfoTooltip source={helpContext} path="studios/studio-gpio-pin"/></>,
                <>Type<InfoTooltip source={helpContext} path="studios/studio-gpio-type"/></>,
                ""]}
              rows={editor.data.gpio.actions.map((action, index) => action.remove ? [] : [
                <></>,
                <Select disabled={editor.licenses.exceeded || editor.data.show !== null} value={action.action} onChange={(value) => { updateStudioGPIOAction(index, {action: value}); }}>{GPIOActions.map(({id, name}) => <option value={id}>{name}</option>)}</Select>,
                <Input disabled={editor.licenses.exceeded || editor.data.show !== null} value={action.channel} onChange={(value) => { updateStudioGPIOAction(index, {channel: value}); }}/>,
                <Select disabled={editor.licenses.exceeded || editor.data.show !== null} value={action.pin} onChange={(value) => { updateStudioGPIOAction(index, {pin: value}); }}>{GPIOPinTypes.map(({id, name}) => <option value={id}>{name}</option>)}</Select>,
                <Select disabled={editor.licenses.exceeded || editor.data.show !== null} value={action.tosrc} onChange={(value) => { updateStudioGPIOAction(index, {tosrc: value}); }}>{GPIOModes.map(({id, name}) => <option value={id}>{name}</option>)}</Select>,
                <Button disabled={editor.licenses.exceeded || editor.data.show !== null} onClick={ async () => { if (await Confirm('Delete GPIO action?', Warning)) deleteStudioGPIOAction(index); }}>Delete</Button>
              ]).concat([[
                <></>,
                <></>,
                <></>,
                <></>,
                <></>,
                <Button disabled={editor.licenses.exceeded || editor.data.show !== null} color="blue" onClick={() => { addStudioGPIOAction(); }}>Add</Button>
              ]])}/>
            <br/>
            <h3>GPIO Indications</h3>
            <Table
              columnWidths={[LabelWidth].concat(["1px", "1px", "1px", "1px"])} //TODO
              headers={[
                "",
                <>Action<InfoTooltip source={helpContext} path="studios/studio-gpio-action"/></>,
                <>Channel<InfoTooltip source={helpContext} path="studios/studio-gpio-channel"/></>,
                <>Pin<InfoTooltip source={helpContext} path="studios/studio-gpio-pin"/></>,
                <>Type<InfoTooltip source={helpContext} path="studios/studio-gpio-type"/></>,
                ""]}
              rows={editor.data.gpio.indications.map((indication, index) => indication.remove ? [] : [
                <></>,
                <Select disabled={editor.licenses.exceeded || editor.data.show !== null} value={indication.action} onChange={(value) => { updateStudioGPIOIndication(index, {action: value}); }}>{GPIOIndications.map(({id, name}) => <option value={id}>{name}</option>)}</Select>,
                <Input disabled={editor.licenses.exceeded || editor.data.show !== null} value={indication.channel} onChange={(value) => { updateStudioGPIOIndication(index, {channel: value}); }}/>,
                <Select disabled={editor.licenses.exceeded || editor.data.show !== null} value={indication.pin} onChange={(value) => { updateStudioGPIOIndication(index, {pin: value}); }}>{GPIOPinTypes.map(({id, name}) => <option value={id}>{name}</option>)}</Select>,
                <Select disabled={editor.licenses.exceeded || editor.data.show !== null} value={indication.tosrc} onChange={(value) => { updateStudioGPIOIndication(index, {tosrc: value}); }}>{GPIOModes.map(({id, name}) => <option value={id}>{name}</option>)}</Select>,
                <Button disabled={editor.licenses.exceeded || editor.data.show !== null} onClick={ async () => { if (await Confirm('Delete GPIO indication?', Warning)) deleteStudioGPIOIndication(index); }}>Delete</Button>,
              ]).concat([[
                <></>,
                <></>,
                <></>,
                <></>,
                <></>,
                <Button disabled={editor.licenses.exceeded || editor.data.show !== null} color="blue" onClick={() => { addStudioGPIOIndication(); }}>Add</Button>
              ]])}/>
            <br/>
            <div className="btn-row">
              <Button disabled={editor.licenses.exceeded} color="blue" type="submit">Save</Button>
            </div>
          </form>
        </Panel>
      </Row>
    </>);
  }

  function RenderAddressbook() {
    const [loading, setLoading] = useState(false);
    const [entries, setEntries] = useState([]);
  
    function refreshEntries() {
      RequestAPI(apiUrl, null, null, data => {
        setLoading(false);
        setEntries(data);
      });
    }
  
    useEffect(() => {
      setLoading(true);
      refreshEntries();
    }, []);
  
    return (<>
      <LoadIndicator open={loading}/>
      <Breadcrumb item="Addressbook" path={[
        { link: '/studios', text: 'Studios Configuration' }
      ]}/>
      <Warnings value={warnings}/>
  
      <h1>Studios Address Books</h1>
      <br/>
  
      <Row spacing={sitePadding}>        
        <Panel title="Studios">
          <Table
            alignLabelsLeft={true}
            headers={[
              <>Studio Name<InfoTooltip source={helpContext} path="studios/addressbooks-studio"/></>,
              <>Address Book Entries<InfoTooltip source={helpContext} path="studios/addressbooks-entries"/></>,
              ""]}
            rows={entries.map((entry) => [
              entry.name,
              entry.nab,
              <Link to={'/studios/'+ addressbookUrl + '/'  + entry.id}><Button>Edit</Button></Link>
            ])} />
        </Panel>
      </Row>
    </>);
  }
  
  function RenderAddressbookEditor() {
    const Warning = { variant: 'warning' }; //Alert, Confirm
    const UnsavedMessage = 'Address book data has been changed but haven\'t been saved. Continue?';
  
    const [loading, setLoading] = useState(false);
    const [unsaved, setUnsaved] = useState(false);
    const [editor, setEditor] = useState({data: []});
    
    function editAddressBook(id) {
      RequestAPI(apiUrl, null, null, (studios) => {
        let found = false;
        studios.map((studio) => {
          if (studio.id === Number(id)) found = true;
          return false;
        });

        if (found) RequestAPI(apiUrl + '/' + id, null, null, (studio) => {
          RequestAPI(apiUrl + '/' + id + '/' + addressbookUrl, null, null, (data) => {
            setLoading(false);
            setEditor({id: id, name: studio.studio.name, data: data});
          });
        }); else history.replace(path + '/' + addressbookUrl);
      });
    }
    function addAddressBook() {
      let ab = _clone(editor.data);
      ab.push({add: true});
      setEditor(_merge({}, editor, {data: ab}));
      setUnsaved(true);
    }
    function updateAddressBook(index, value) {
      let ab = _clone(editor.data);
      ab[index] = _merge(ab[index], value);
      ab[index].update = true;
      setEditor(_merge({}, editor, {data: ab}));
      setUnsaved(true);
    }
    function deleteAddressBook(index) {
      let ab = _clone(editor.data);
      if (ab[index].add) ab.splice(index, 1); else ab[index].remove = true;
      setEditor(_mergeArray({}, editor, {data: ab}));
      setUnsaved(true);
    }
    function saveAddressBook(e) {
      e.preventDefault();
  
      setUnsaved(false);
  
      let addEntries = editor.data.filter(entry => entry.add);
      let removeEntries = editor.data.filter(entry => entry.remove);
      let updateEntries = editor.data.filter(entry => !entry.add && entry.update);
  
      let id = editor.id;
      let next = () => save(editor.id, addEntries, removeEntries);
      let save = (id, addEntries, removeEntries) => {
        if (addEntries.length) {
          let entry = addEntries.shift();
          if (!entry.name && entry.key) entry.name = entry.key;
          if (!entry.key && entry.name) entry.key = entry.name;
          RequestAPI(apiUrl + '/' + id + '/' + addressbookUrl, entry, RequestMethods.POST, () => { next(); });
        } else if (removeEntries.length) {
          let entry = removeEntries.shift();
          RequestAPI(apiUrl + '/' + id + '/' + addressbookUrl + '/' + entry.id, null, RequestMethods.DELETE, () => { next(); });
        } else if (updateEntries.length) {
          let entry = updateEntries.shift();
          RequestAPI(apiUrl + '/' + id + '/' + addressbookUrl + '/' + entry.id, entry, RequestMethods.POST, () => { next(); });
        } else editAddressBook(id);
      }
  
      setLoading(true);
      save(id, addEntries, removeEntries);
    }
  
    let { id } = useParams();
    useEffect(() => {
      setLoading(true);
      editAddressBook(id);
    }, [id]);
  
    return (<>
      <LoadIndicator open={loading}/>
      <Breadcrumb item="Entries" path={[
        { link: '/studios', text: 'Studios Configuration' },
        { link: '/studios/' + addressbookUrl, text: 'Addressbook' }
      ]}/>
      <Warnings value={warnings}/>
  
      <h1>Studio "{editor.name}" Configuration</h1>
      <br/>
  
      <Row spacing={sitePadding}>
        <Panel title="Address Book">
          <Prompt when={unsaved} message={UnsavedMessage}/>
  
          <form onSubmit={saveAddressBook}>
            <Table
              alignLabelsLeft={true}
              headers={[
                <>Name<InfoTooltip source={helpContext} path="studios/addressbook-name"/></>,
                <>Number / SIP Address<InfoTooltip source={helpContext} path="studios/addressbook-address"/></>,
                ""]}
              rows={editor.data.map((ab, index) => ab.remove ? [] : [
                <Input value={ab.name} autoFocus={index === 0} onChange={(value) => { updateAddressBook(index, {name: value}); }}/>,
                <Input value={ab.key} onChange={(value) => { updateAddressBook(index, {key: value}); }}/>,
                <Button onClick={ async () => { if (await Confirm('Delete entry?', Warning)) deleteAddressBook(index); }}>Delete</Button>
              ]).concat([[
                <></>,
                <></>,
                <Button color="blue" onClick={() => { addAddressBook(); }}>Add</Button>
              ]])}/>
            <div className="btn-row">
              <Button color="blue" type="submit">Save</Button>
            </div>
          </form>
        </Panel>
      </Row>
    </>);
  }  

  return (<Switch>
    <Route exact path={path}>
      <RenderStudios/>
    </Route>
    <Route exact path={`${path}/${addressbookUrl}`}>
      <RenderAddressbook/>
    </Route>
    <Route path={`${path}/${addressbookUrl}/:id`}>
      <RenderAddressbookEditor/>
    </Route>
    <Route path={`${path}/:id`}>
      <RenderStudioEditor/>
    </Route>
  </Switch>);
};

export { StudiosPage };