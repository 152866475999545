import React, { useState, useEffect } from "react";
import { Row, Panel, Table, Button, Input } from "@telosalliance/ui-core";
import { RequestAPI, LoadIndicator, Breadcrumb, Warnings, RequestMethods, InfoTooltip } from "../Utils";
import { clone as _clone } from "lodash"
import { alert as Alert, confirm as Confirm } from "@telosalliance/ui-core-framework";

const LicensingPage = ({ sitePadding, helpContext, warnings }) => {
  const Warning = { variant: 'warning' }; //Alert, Confirm

  const [loading, setLoading] = useState(false);
  const [configuration, setConfiguration] = useState({ nodeName: '' });
  const [license, setLicense] = useState('');
  const [licenses, setLicenses] = useState({ licenses: [], activation: {} });

  let days = 60 * 60 * 24;
  let date = Math.floor(Date.now() / 1000);
  let expireTimeWarning = date + days * 30;
  function formatExpiration(exp) {
    let expDays = Math.floor((exp.expireTime - date) / days);
    if (expDays === 0) {
      let seconds = exp.expireTime - date;
      let minutes = Math.floor(seconds % (60 * 60) / 60);
      let hours = Math.floor(seconds / 60 / 60);
      return 'In ' + (hours ? hours + ' hour(s), ' : '') + minutes + ' minute(s)';
    } else return exp.expireTime >= date ? 'In ' + expDays + ' day(s)' : 'Expired';
  }

  function refreshLicenses() {
    let updateLicenses = _clone(licenses);
    RequestAPI('/licensing/node_name', null, null, data => {
      setConfiguration({ nodeName: data.nodeName });
      RequestAPI('/licensing/licenses', null, null, data => {
        if (Array.isArray(data)) data.sort();
        updateLicenses.licenses = data;
        RequestAPI('/licensing/activation/features', null, null, data => {
          setLoading(false);

          updateLicenses.activation = data.licensesData;
          setLicenses(updateLicenses);
        });
      });
    });
  }

  function saveConfiguration() {
    setLoading(true);
    RequestAPI('/licensing/node_name', { nodeName: configuration.nodeName }, RequestMethods.POST, data => { refreshLicenses(); });
  }

  function refreshActivation() {
    setLoading(true);
    RequestAPI('/licensing/activation/all', {}, RequestMethods.POST, data => { refreshLicenses(); });
  }

  function addLicense(e) {
    e.preventDefault();

    setLoading(true);
    RequestAPI('/licensing/licenses', { license: license, activate: true }, RequestMethods.POST, data => {
      if (data && data.code && data.error_message) Alert('Cannot add license: ' + data.error_message, Warning);
      setLicense('');
      refreshLicenses();
    });
  }

  function releaseLicenses(value) {
    setLoading(true);
    RequestAPI('/licensing/activation', null, RequestMethods.DELETE, data => refreshLicenses());
  }

  function removeLicense(value) {
    setLoading(true);
    RequestAPI('/licensing/licenses', { license: value }, RequestMethods.DELETE, data => refreshLicenses());
  }

  useEffect(() => {
    setLoading(true);
    refreshLicenses();
  }, []);

  return (
    <>
      <LoadIndicator open={loading}/>
      <Breadcrumb item="Licenses Management"/>
      <Warnings value={warnings} exclude={["licensing"]}/>

      <h1>Licenses Management</h1>
      <br/>

      <Row spacing={sitePadding}>
        <Panel title="Configuration">
          <form onSubmit={saveConfiguration}>
            <Table
              rows={[
                ["Node name", <><Input value={configuration.nodeName} onChange={(value) => setConfiguration({nodeName: value})}/><InfoTooltip source={helpContext} path="licenses/node-name"/></>],
                ["Activation", <><Button color="green" onClick={() => refreshActivation()}>Refresh</Button><InfoTooltip source={helpContext} path="licenses/refresh-activation"/></>],
                ["", <><Button onClick={async () => {
                    if (await Confirm('This will deactivate all the licenses. Continue?', Warning)) releaseLicenses(license)}
                  }>Release</Button><InfoTooltip source={helpContext} path="licenses/release-activation"/></>]
              ]}/>
            <div className="btn-row">
              <Button color="blue" type="submit">Save</Button>
            </div>
          </form>
        </Panel>
        <Panel title="Add License" className="addLicense">
          <form onSubmit={addLicense}>
            <Table
              rows={[
                ["License", <><Input value={license} onChange={(value) => setLicense(value)}/><InfoTooltip source={helpContext} path="licenses/add-license"/></>]
              ]}/>
            <div className="btn-row">
              <Button color="blue" type="submit">Add</Button>
            </div>
          </form>
        </Panel>
        {licenses.licenses && licenses.licenses.length ? 
          <Panel title="Licenses">
            <Table
              alignLabelsLeft={true}
              className="licensesList"
              rows={licenses.licenses.filter((license) => license[0] !== '_').reduce((arr, license) => {
                let activations = licenses.activation[license] && licenses.activation[license].features;
                arr.push([<><hr/><h3>{license + (activations ? '' : ' (Invalid)')}</h3></>]);
                if (activations) arr.push([<Table
                  className="featuresList"
                  rows={[[<Table
                    headers={[
                      "",
                      <>Feature Count<InfoTooltip source={helpContext} path="licenses/licenses-feature-count"/></>,
                      <>Expires<InfoTooltip source={helpContext} path="licenses/licenses-expires"/></>]}
                    rows={Object.keys(activations).map((name) => {
                      let activation = activations[name];
                      return [
                        activation.dispName,
                        activation.leasedCount + ' / ' + activation.value,
                        <span class={activation.exp.expireTime < expireTimeWarning ? 'warning' : ''}>{activation.exp.expireTime ? formatExpiration(activation.exp) : "Never"}</span>
                      ];
                    })}/>]]}/>]);
                arr.push([<Button color="blue" onClick={async () => {
                  if (await Confirm('This will remove the license "' + license + '". Continue?', Warning)) removeLicense(license)}
                }>Remove</Button>]);

                return arr;
              }, [])}/>
          </Panel>
        : <></> }
      </Row>
    </>
  );
}

export { LicensingPage };
