import React, { useEffect } from 'react';
import { Logout } from '../Utils';

const LogoutPage = ({ history, sitePadding, setIsLoggedIn }) => {
  useEffect(() => {
    Logout(() => {
      setIsLoggedIn(false);
      history.replace('/');
    });
  }, [history]);

  return (<></>);
};

export { LogoutPage };
