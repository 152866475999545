import React, { useState, useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Row, Panel, Table, Button, Input } from '@telosalliance/ui-core';
import { Login, LoginState, RequestAPI, LoadIndicator } from '../Utils';
import { alert as Alert, confirm as Confirm, useSessionStorage } from "@telosalliance/ui-core-framework"

const LoginPage = ({ history, sitePadding, isLoggedIn, setIsLoggedIn, setHelpContext }) => {
  const Error = { variant: 'error' }; //Alert, Confirm
  const Warning = { variant: 'warning' };

  const [loading, setLoading] = useState(false);
  const [loginUser, setLoginUser] = useSessionStorage('username', 'user');
  const [loginPassword, setLoginPassword] = useState('');

  let handleLogin = (e) => {
    e.preventDefault();

    setLoading(true);
    Login(loginUser, loginPassword, (success, reply) => {
      setLoading(false);

      if (success) {
        setIsLoggedIn(true);
        RequestAPI('/help/context', null, null, (helpContext) => {
          setHelpContext(helpContext);
        });
      } else {
        if (reply && reply.code === 'EEMAILCONFIRMATIONREQUIRED') {
          (async () => {
            if (await Confirm('Email address hasn\'t been confirmed. Press OK to open email confirmation page.', Warning)) {
              history.push('account/register/confirm/' + encodeURI(loginUser));
            }
          })();
        } else Alert('Invalid username and/or password', Error);
        setLoginPassword('');
      }
    });
  }

  useEffect(() => {
    LoginState((state) => {
      setIsLoggedIn(state);
    });
  }, []);

  return (
    <>
      <LoadIndicator open={loading}/>

      <Row justify="center" style={{ paddingTop: sitePadding }} className="login">
        <Panel title="Login">
          {!isLoggedIn ? (
            <form onSubmit={handleLogin}>
              <Table
                columnWidths={["*", "200px"]}
                rows={[
                  ["TelosCare ID", <Input value={loginUser} onChange={setLoginUser}/>],
                  ["Password", <Input type="password" value={loginPassword} onChange={setLoginPassword} />],
                  [
                    null,
                    <div>
                      <Button
                        color="blue"
                        type="submit"
                      >Log In</Button>
                    </div>,
                  ],
                  [<Link to="/account/reset">Forgot password</Link>],
                  [<div class="separator">or</div>],
                  [<Link to="/account/register">Click here to register for your TelosCare ID</Link>],
                ]}
              />
            </form>
          ) : ( <Redirect to="/system"/> )}
        </Panel>
      </Row>
    </>
  );  
};

export { LoginPage };
