import { pushNotification, useSessionStorage } from '@telosalliance/ui-core-framework';
import { InfoTooltip as UicInfoTooltip } from '@telosalliance/ui-core';
import md5 from 'md5'
import Cookies from 'universal-cookie';
import React, { useState, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import { PulseLoader } from 'react-spinners';
import MuiModal from "@material-ui/core/Modal";
import { cloneDeep as _clone, mergeWith as _mergeWith } from 'lodash';

const IPv4_REGEX = /^(?!.*\.$)((?!0\d)(1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/;
const IPv4_MULTICASTP_REGEX = /^2(?:2[4-9]|3\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d?|0)){3}(:[0-9]+)?$/;

const cookies = new Cookies();
const authUrl = '/auth';
const apiUrl = '/api';
const urlPrefix = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port;

let setIsLoggedIn = null
let setHeaders = null;

const SetApplication = (props) => {
  if (props.setIsLoggedIn) setIsLoggedIn = props.setIsLoggedIn;
  if (props.setHeaders) setHeaders = props.setHeaders;
}

const ValidateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

const IsValidIPv4 = (stream) => {
  return IPv4_REGEX.test(stream);
}

const IsValidIPv4MulticastP = (stream) => {
  return IPv4_MULTICASTP_REGEX.test(stream);
}

const IsValidLwChannel = (stream) => {
  const nstream = Number(stream);
  return (String(nstream) === String(stream)) && (nstream >= -32767) && (nstream <= 32767);
}

const RequestMethods = {
  GET: 'GET',
  PUT: 'PUT',
  POST: 'POST',
  DELETE: 'DELETE'
}

const Request = (url, data = null, method = null, callback = null, callback_error = null, args = null) => {
  if (method === null) method = 'GET';

  method = method.toUpperCase().trim();
  var overrideMethod = null;
  switch (method) {
    case 'GET':
    case 'POST':
      //do nothing
      break;
    case 'DELETE':
    default:
      overrideMethod = method;
      method = 'POST';
      break;
  }

  let headers = {
    'origin': window.location.origin,
    'x-authentication-method': 'oauthdigest'
  };
  if (overrideMethod) headers['x-http-method-override'] = overrideMethod;

  let body = null;
  if (data) {
    if (!Array.isArray(data) && data.entries && typeof(data.entries) == 'function') body = data;
    else body = JSON.stringify(data);
  }

  let fetchUrl = url;
  if (urlPrefix) fetchUrl = urlPrefix + fetchUrl;
  fetch(fetchUrl, {
    body: body,
    cache: 'no-cache',
    credentials: 'include',
    headers: headers,
    method: method,
    mode: 'cors'
  })
    .then(response => {
      if (setHeaders && response.headers) setHeaders(response.headers);
      return response.json();
    })
    .then(data => {
      console.log('Request reply:', url, data);
      if (data && data.error && data.code && (data.code === 'EKEYEXPIRED' || data.code === 'EKEYINVALID')) {
        if (setIsLoggedIn) setIsLoggedIn(false);
        if (url === authUrl + '/verify') callback(data);
      } else {
        if (callback) callback(data);
      }
    })
    .catch(err => {
      if (err.message) pushNotification('Request error: ' + err.message);
      console.error('Request error:', err);
      if (callback_error) callback_error(err, args);
    });
}

const RequestAPI = (url, data = null, method = null, callback = null, callback_error = null, args = null) => {
  return Request(apiUrl + encodeURI(url), data, method, callback, callback_error, args);
}

const Login = (username, password, callback = null) => {
  console.log('Utils.Login USR "' + username + '", PWD "' + password + '"');

  if (!username) return false;

  Request(authUrl, null, null, (authdata) => {
    if (authdata.realm && authdata.nonce && authdata.opaque) {
      var reqdata = {};
      reqdata.realm = authdata.realm;
      reqdata.nonce = authdata.nonce;
      reqdata.opaque = authdata.opaque;
      reqdata.username = username;
      reqdata.cnonce = md5(Math.random() + '');
      var ha1 = md5(String(username).toLowerCase() + ':' + authdata.realm + ':' + password);
      reqdata.response = md5(ha1 + ':' + reqdata.nonce + ':' + reqdata.cnonce + ':' + reqdata.opaque);

      let url = new URL('http://w');
      Object.keys(reqdata).forEach(key => url.searchParams.append(key, reqdata[key]));

      Request(authUrl + '?' + url.searchParams.toString(), null, null, (authresponse) => {
        if (authresponse.error) {
          cookies.remove('key', { path: '/' });
          //alert('Error: ' + JSON.stringify(authresponse));
          if (callback) callback(false, authresponse);
        } else if (authresponse.key) {
          let d = new Date();
          d.setTime(d.getTime() + 1000 * 60 * 60 * 24); //1 day expiration
          cookies.set('key', authresponse.key, { expires: d, path: '/' });
          if (callback) callback(true);
        }
      });
    } else console.error('Invalid response from web server!');
  });

  return true;
}

const LoginState = (callback) => {
  if (cookies.get('key')) {
    Request(authUrl + '/verify', null, null, (data) => {
      callback((data.key && data.key === cookies.get('key')) ? true : false);
    });
  } else callback(false);

  return true;
}

const Logout = (callback = null) => {
  if (cookies.get('key') != null) {
    Request(authUrl + '/logout', null, null, () => {
      cookies.remove('key', { path: '/' });
      if (callback) callback(true);
    });
  } else {
    if (callback) callback(false);
  }

  return true;
}

const LoadIndicator = ({
  className = "",
  style,
  children,
  buttons,
  color = "#aaaaaa",
  open = false,
  closeDelay = 200,
  ...props
}) => {
  const [isOpen, setIsOpen]  = useState(false);
  useLayoutEffect(() => {
    if (isOpen === open) return;

    if (open) setIsOpen(true);
    else {
      let f = () => { setIsOpen(false); };
      if (closeDelay === 0) f(); else setTimeout(f, closeDelay);
    }
  }, [open, closeDelay]);

  return (
    <MuiModal open={isOpen} {...props}>
      <div className={`loading-indicator ${className}`} style={style}>
        <PulseLoader
            color={color}
            loading={true} 
          />
      </div>
    </MuiModal>
  );
};

const Notification = ({
  className = "notification",
  visible = true,
  children,
  ...props
}) => {
  return (visible ? <div class={className} {...props}>{children}</div> : <></>)
}

const Breadcrumb = ({
  className = "panel",
  homePath = "/system",
  item = null,
  path = null,
  ...props
}) => {
  return (
    <ul className={`breadcrumb ${className}`} {...props}>
      {homePath && <li><Link to={homePath}>Home</Link></li>}
      {path && path.map((item, index) => <li><Link to={item.link} onClick={(e) =>{ if(item.action) { e.preventDefault(); item.action(); }}}>{item.text}</Link></li>)}
      {item && <li>{item}</li>}
    </ul>
  );
};

const Warnings = ({
  className = "panel",
  value = null,
  exclude = [],
  ...props
}) => {
  let messages = Array.isArray(value) && value.filter((message) => !exclude.includes(message.type));
  return (messages.length ?
    <ul className={`${className} warnings`} {...props}>
      {messages.map((message) => <li>{message.text}</li>)}
    </ul>
  : <></>);
};

const InfoTooltip = ({
  source = null,
  path = null,
  ...props
}) => {
  const [helpEditorEnabled] = useSessionStorage('helpEditorEnabled', false);
  let value = source && source[path];
  return helpEditorEnabled ? (value
    // eslint-disable-next-line
    ? <a href="#" title={path} onClick={(e) => e.preventDefault()}><UicInfoTooltip children={value} {...props}/></a>
    : <Link to={"/help-context/" + path}><UicInfoTooltip  {...props} style={{...props.style, ...{color: 'var(--orange)'}}}/></Link>
  ) : (value ? <UicInfoTooltip children={value} {...props}/> : <></>);
};

const _mergeArray = (obj, ...value) => { return _mergeWith(obj, ...value, (o, v) => { if (Array.isArray(o)) return _clone(v); }); }
const RequestPrefix = () => { return urlPrefix; }

export {
  SetApplication,
  ValidateEmail,
  IsValidIPv4,
  IsValidIPv4MulticastP,
  IsValidLwChannel,
  Request,
  RequestMethods,
  RequestAPI,
  RequestPrefix,
  LoadIndicator,
  Login,
  Logout,
  LoginState,
  Breadcrumb,
  Notification,
  Warnings,
  InfoTooltip,
  _mergeArray
};