import React, { useState, useEffect } from "react";
import { Routing, useSessionStorage } from "@telosalliance/ui-core-framework";
import { SetApplication, RequestAPI } from './Utils';

import SettingsIcon from "@material-ui/icons/Settings";
import VpnLockIcon from '@material-ui/icons/VpnLock';
import DialerSipIcon from '@material-ui/icons/DialerSip';
import MicIcon from '@material-ui/icons/Mic';
import MovieIcon from '@material-ui/icons/Movie';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import InfoIcon from '@material-ui/icons/Info';
import PeopleIcon from '@material-ui/icons/People';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';

import navLogo from "./media/vxs-logo.svg";
import miniNavLogo from "./media/ta-logo-mini.png";
import brandLogo from "./media/ta-logo.png";

import { LoginPage } from "./routes/Login";
import { LogoutPage } from "./routes/Logout";
import { AccountPage } from "./routes/Account";
import { SystemPage } from "./routes/System";
import { MainPage } from "./routes/Main";
import { SIPPage } from "./routes/SIP";
import { StudiosPage } from "./routes/Studios";
import { ShowsPage } from "./routes/Shows";
import { HybridPage } from "./routes/Hybrid";
import { TonesPage } from "./routes/Tones";
import { InfoPage } from "./routes/Info";
import { BackupPage } from "./routes/Backup";
import { LicensingPage } from "./routes/Licensing";
import { LicensingServerPage } from "./routes/LicensingServer";
import { HelpEditorPage } from "./routes/HelpEditor";

const App = () => {
  let licensingEvent = 0;

  const [isLoggedIn, setIsLoggedIn] = useSessionStorage(false);
  const [warnings, setWarnings] = useSessionStorage('warnings');
  const [helpContext, setHelpContext] = useState({});

  const setHeaders = (headers) => {
    let eventUpdates = headers.get('x-event-updates');
    if (eventUpdates) {
      try {
        let updates = JSON.parse(eventUpdates);
        let licensingUpdate = updates.licensing;
        if (licensingUpdate !== licensingEvent) {
          let days = 60 * 60 * 24;
          let date = Math.floor(Date.now() / 1000);
          let expireTimeWarning = date + days * 30;
          licensingEvent = licensingUpdate;

          RequestAPI('/licensing/activation/features', null, null, data => {
            let messages = [];
            let minExpireTime = 0;
            for (let lk in data.licensesData) {
              let license = data.licensesData[lk];
              for (let fk in license.features) {
                let feature = license.features[fk];
                if (feature.exp.expireTime && (feature.exp.expireTime >= date) && (feature.exp.expireTime < expireTimeWarning)) {
                  if (!minExpireTime || (feature.exp.expireTime < minExpireTime)) minExpireTime = feature.exp.expireTime;
                }
              }
            }

            if (minExpireTime) {
              let expDays = Math.floor((minExpireTime - date) / days);
              messages.push({
                type: 'licensing',
                text: 'One or more licenses will expire in ' +
                  (expDays === 0 ? 'less than one day' : Math.floor((minExpireTime - date) / days) + ' day(s)') +
                  '. Please view the Licenses page for more information.'
              });
            }

            setWarnings(messages);
          });
        }
      } catch {}
    }
  }

  useEffect(() => {
    SetApplication({setIsLoggedIn, setHeaders});
    if (isLoggedIn) RequestAPI('/help/context', null, null, (data) => { setHelpContext(data); });
  }, [])

  return (
    <>
      <Routing
        isLoggedIn={isLoggedIn}
        navLogo={navLogo}
        miniNavLogo={miniNavLogo}
        brandLogo={brandLogo}
        deviceInfo={null}
        home={{
          component: LoginPage,
          props: {isLoggedIn, setIsLoggedIn, setHelpContext}
        }}
        routes={[
          {
            path: "/account",
            name: "Account",
            component: AccountPage,
            props: {isLoggedIn, warnings},
            navItemProps: { hidden: true },
            isProtected: false,
            exact: false
          },
          {
            path: "/system",
            name: "System",
            icon: SettingsIcon,
            component: SystemPage,
            props: {warnings},
            isProtected: true
          },
          {
            path: "/config",
            name: "Main",
            icon: VpnLockIcon,
            component: MainPage,
            props: {setIsLoggedIn, helpContext, warnings},
            isProtected: true
          },
          {
            path: "/sip",
            name: "SIP",
            icon: DialerSipIcon,
            component: SIPPage,
            props: {helpContext, warnings},
            isProtected: true,
            exact: false
          },
          {
            path: "/studios",
            name: "Studios",
            icon: MicIcon,
            component: StudiosPage,
            props: {helpContext, warnings},
            isProtected: true,
            exact: false,
            subRoutes: [
              {
                path: '/addressbook',
                name: 'Address Book',
                props: {warnings},
                isProtected: true,
                exact: false
              }
            ]
          },
          {
            path: "/shows",
            name: "Shows",
            icon: MovieIcon,
            component: ShowsPage,
            props: {helpContext, warnings},
            isProtected: true,
            exact: false,
            subRoutes: [
              {
                path: '/addressbook',
                name: 'Address Book',
                isProtected: true,
                exact: false
              }
            ]
          },
          {
            path: "/hybrid",
            name: "Call Processing",
            icon: EqualizerIcon,
            component: HybridPage,
            props: {helpContext, warnings},
            isProtected: true
          },
          {
            path: "/tones",
            name: "Tones",
            icon: AudiotrackIcon,
            component: TonesPage,
            props: {helpContext, warnings},
            isProtected: true,
            subRoutes: [
              {
                path: '/dtmf',
                name: 'DTMF',
                component: TonesPage,
                props: {helpContext, warnings, pageType: 'dtmf'},
                isProtected: true,
              },
              {
                path: '/ringtones',
                name: 'Ringtones',
                component: TonesPage,
                props: {helpContext, warnings, pageType: 'ringtone'},
                isProtected: true,
              }
            ]
          },
          {
            path: "/info",
            name: "Information",
            icon: InfoIcon,
            component: InfoPage,
            props: {},
            isProtected: true,
            subRoutes: [
              {
                path: '/studios',
                name: 'Studios',
                component: InfoPage,
                props: {warnings, pageType: 'studios'},
                isProtected: true,
                exact: false
              },
              {
                path: '/devices',
                name: 'Devices',
                component: InfoPage,
                props: {warnings, pageType: 'devices'},
                isProtected: true,
              },
              {
                path: '/streams',
                name: 'Streams',
                component: InfoPage,
                props: {warnings, pageType: 'streams'},
                isProtected: true,
              },
              {
                path: '/system',
                name: 'System',
                component: InfoPage,
                props: {warnings, pageType: 'system'},
                isProtected: true,
              },
            ]
          },
          {
            path: "/licenses",
            name: "Licenses",
            icon: VpnKeyIcon,
            component: LicensingPage,
            props: {helpContext, warnings},
            isProtected: true
          },
          {
            path: "/license-server",
            name: "License Server",
            component: LicensingServerPage,
            props: {helpContext, warnings},
            navItemProps: { hidden: true },
            isProtected: true
          },
          {
            path: "/users",
            name: "Users",
            icon: PeopleIcon,
            component: AccountPage,
            props: {isLoggedIn, helpContext, warnings},
            isProtected: true,
            exact: false
          },
          {
            path: "/backup",
            name: "Backup/Restore",
            icon: SettingsBackupRestoreIcon,
            component: BackupPage,
            props: {helpContext, warnings},
            isProtected: true
          },
          {
            path: "/help-context",
            name: "Help Context",
            component: HelpEditorPage,
            props: {warnings, helpContext, setHelpContext},
            navItemProps: { hidden: true },
            isProtected: true,
            exact: false
          },
          {
            path: "/logout",
            name: "Logout",
            icon: ExitToAppIcon,
            component: LogoutPage,
            props: {setIsLoggedIn},
            isProtected: true
          },
        ]}
      />
    </>
  );  
}

export default App;
