import React, { useState, useEffect } from "react";
import { Row, Panel, Table, Button } from "@telosalliance/ui-core";
import { Redirect, Link, Switch, Route, useRouteMatch, useParams } from 'react-router-dom';
import { RequestAPI, LoginState, Breadcrumb, Warnings, LoadIndicator } from '../Utils';

const InfoPage = ({ sitePadding, history, pageType, warnings }) => {
  const { path } = useRouteMatch();

  function formatChannel(ch) {
    if (ch && ch.channel) {
      switch (ch.mode) {
        case 'LW_TO_SRC': return '-' + ch.channel;
        default: return ch.channel;
      }
    } else return '0';
  }
  function formatAecChannel(ch) {
    let i = [];
    switch (ch.mode) {
      case 'LW_FROM_SRC': i.push('From Source'); break;
      case 'LW_TO_SRC': i.push('To Source'); break;
      default: break;
    }
    i.push(ch.channel ? ch.channel : '-');
    return i.join(', ');
  }
  function capitalize(s) {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  function RenderStudioInformation() {
    const [loading, setLoading] = useState(false);
    const [studio, setStudio] = useState({
      shows: [],
      studio: {
        ch_fix: [],
        ch_sel: [],
        devices: [],
        lines: []
      },
      phold: {},
      aec: null,
      gpio: { actions: [], indications: [] }
    });

    let { id } = useParams();
    useEffect(() => {
      switch (pageType) {
        case 'studios':
          setLoading(true);
          RequestAPI('/' + pageType, null, null, (studios) => {
            let found = false;
            studios.map((studio) => {
              if (studio.id === Number(id)) found = true;
              return false;
            });

            if (found) RequestAPI('/' + pageType + '/' + id, null, null, (data) => {
              setLoading(false);
              setStudio(data);
            }); else history.replace(path);
          });
          break;
        default:
          break;
      }
    }, [id]);

    return (<>
      { pageType === 'studios' ?
        <>
          <LoadIndicator open={loading}/>
          <Breadcrumb item="Studio" path={[
            { link: '/info', text: 'Information'},
            { link: '/info/studios', text: 'Studios'}
          ]}/>
          <Warnings value={warnings}/>

          <h1>Information</h1>
          <br/>
          
          <Row spacing={sitePadding}>
            <Panel title="Studio" className="studio">
              <h3>Status</h3>
              <Table
                rows={[
                  ["Name", <>{studio.studio.name}</>],
                  ["Lines", <>{studio.studio.num_lines}</>],
                  ["Fixed Channels", <>{studio.studio.ch_fix.length}</>],
                  ["Selectable Channels", <>{studio.studio.ch_sel.length}</>],
                  ["Attached Devices", <>{studio.studio.devices.length}</>],
                  ["Active Show", <>{studio.shows.filter((show) => show.id === studio.show).concat([{name: "No Show"}])[0].name}</>]
                ]}/>

              <hr/>
              <h3>Fixed Channels</h3>
              <Table
                rows={[
                  ["", 
                    <Table
                      className="channels"
                      headers={["Id", "Receive Channel", "Send Channel", "Name"]}
                      rows={studio.studio.ch_fix.map((ch, index) => [
                        <>{index + 1}</>,
                        <>{formatChannel(ch.recv)}</>,
                        <>{formatChannel(ch.send)}</>,
                        <>{ch.name}</>
                      ])}/>
                  ],
                ]}/>
              
              <hr/>
              <h3>Selectable Channels</h3>
              <Table
                rows={[
                  ["", 
                    <Table
                      className="channels"
                      headers={["Id", "Receive Channel", "Send Channel", "Name"]}
                      rows={studio.studio.ch_sel.map((ch, index) => [
                        <>{index + 1}</>,
                        <>{formatChannel(ch.recv)}</>,
                        <>{formatChannel(ch.send)}</>,
                        <>{ch.name}</>
                      ])}/>
                  ],
                ]}/>
              
              <hr/>
              <h3>Program on Hold</h3>
              <Table
                rows={[
                  ["Channel", <>{formatChannel(studio.phold)}</>],
                ]}/>

              <hr/>
              <h3>Acoustic Echo Canceller</h3>
              {studio.aec ?
                <Table
                  rows={[
                    ["Configured", "Yes"],
                    ["Enabled", <>{studio.aec.enabled ? "Yes" : "No"}</>],
                    ["Mic Input Channel", <>{formatAecChannel(studio.aec.recv_mic)}</>],
                    ["Reference (CRMON) Channel", <>{formatAecChannel(studio.aec.recv_ref)}</>],
                    ["Output (Backfeed) Channel", <>{formatAecChannel(studio.aec.send)}</>],
                  ]}/>
              : <Table
                  rows={[
                    ["Configured", "No"],
                  ]}/>}

              <hr/>
              <h3>Devices</h3>
              <Table
                rows={[
                  ["", studio.studio.devices.length === 0 ? <>No devices attached</> :
                    <Table
                      className="devices"
                      headers={["SID", "Host", "IP", "Port"]}
                      rows={studio.studio.devices.map((device) => [
                        <>{device.sid}</>,
                        <>{device.host}</>,
                        <>{device.ip}</>,
                        <>{device.port}</>
                      ])}
                  />]
                ]}/>

              <hr/>
              <h3>Lines</h3>
              <Table
                rows={[
                  ["", studio.studio.lines.length === 0 ? <>No active lines</> :
                    <Table
                      className="devices"
                      headers={["Position", "Name", "Local Number", "Remote Number", "LW Channel", "Device SID", "Status", "State", "Comment"]}
                      rows={studio.studio.lines.map((line, index) => [
                        <>{index + 1}</>,
                        <>{line.pos ? line.pos : ''}</>,
                        <>{line.local}</>,
                        <>{line.remote}</>,
                        <>{line.channel}</>,
                        <>{line.sid}</>,
                        <>{line.status}</>,
                        <>{line.state}</>,
                        <>{line.comment}</>,
                      ])}
                  />]
                ]}/>
            </Panel>
          </Row>
        </>
      :
        <Redirect to="/info/studios"/>
      }
    </>);
  }

  function RenderInformation() {
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState({data: []});
    const [system, setSystem] = useState({hardware: {memory: [], disk: []}, health: []});

    useEffect(() => {
      if (!pageType) LoginState(() => {});
      else switch(pageType) {
        case 'studios':
          setLoading(true);
          RequestAPI('/studios', null, null, (data) => {
            setLoading(false);
            setInfo({data: data});
          })
          break;
        case 'devices':
          setLoading(true);
          RequestAPI('/info/devices', null, null, (data) => {
            setLoading(false);
            setInfo({data: data});
          })
          break;
        case 'streams':
          setLoading(true);
          RequestAPI('/status/streams', null, null, (data) => {
            setLoading(false);
            setInfo({data: data ? data : []});
          })
          break;
        case 'system':
          setLoading(true);
          RequestAPI('/status/system', null, null, (data) => {
            setLoading(false);
            setSystem(data);
          })
          break;
        default:
          break;
        }
    }, []);
    
    return (<>
      <LoadIndicator open={loading}/>
      <Breadcrumb item={capitalize(pageType)} path={[
        { link: '/info', text: 'Information'}
      ]}/>
      <Warnings value={warnings}/>

      <h1>Information</h1>
      <br/>
      
      { !pageType ?
        <Redirect to="/info/studios"/>
      : pageType === 'studios' ?
        <Row spacing={sitePadding}>
          <Panel title="Studios">
            <Table
              className="config"
              alignLabelsLeft={true}
              headers={["Name", "Lines", "Fixed Channels", "Selectable Channels", "Connected Devices", "Active Show", ""]}
              rows={info.data.map((studio) => [
                <>{studio.name}</>,
                <>{studio.num_lines}</>,
                <>{studio.nfix}</>,
                <>{studio.nsel}</>,
                <>{studio.ndev}</>,
                <>{studio.show}</>,
                <Link to={path + '/' + studio.id}><Button>Select</Button></Link>
              ])}/>
          </Panel>
        </Row>
      : pageType === 'devices' ?
        <Row spacing={sitePadding}>
          <Panel title="Devices">
            <Table
              alignLabelsLeft={true}
              headers={["SID", "Host", "IP", "Port", "Studio"]}
              rows={info.data.map((device) => [
                <>{device.sid}</>,
                <>{device.host}</>,
                <>{device.ip}</>,
                <>{device.port}</>,
                <>{device.studio.name}</>
              ])}/>
          </Panel>
        </Row>
      : pageType === 'streams' ?
        <Row spacing={sitePadding}>
          <Panel title="Stream Statistics" className="streams">
            <table class="uic-table">
              <colgroup span="2"/>
              <colgroup span="6" class="grp"/>
              <colgroup span="5" class="grp"/>
              <colgroup span="3" class="grp"/>
              <colgroup span="3" class="grp"/>
              <colgroup span="5" class="grp"/>
              <colgroup span="7" class="grp"/>
              <colgroup span="5" class="grp"/>
              <colgroup span="3" class="grp"/>
              <thead>
              <tr>
                <th>&nbsp;</th>
                <th style={{border: "none"}}>&nbsp;</th>
                <th colspan="6" class="grp">Input</th>
                <th colspan="5" class="grp">Output</th>
                <th colspan="3" class="grp">Jitter Buffer</th>
                <th colspan="3" class="grp">RX Processing</th>
                <th colspan="5" class="grp">TX Processing</th>
                <th colspan="7" class="grp">RTP Recv Packets</th>
                <th colspan="5" class="grp">RTP Errors</th>
                <th colspan="3" class="grp">RTP Sent Packets</th>
                <th>&nbsp;</th>
                <th style={{border: "none"}}>&nbsp;</th>
                <th style={{border: "none"}}>&nbsp;</th>
              </tr>
              <tr>
                <th>#</th>
                <th>ID</th>
                <th>Address</th>
                <th>Codec</th>
                <th>Channels</th>
                <th>Sample Rate</th>
                <th>Stat Filter</th>
                <th>Recv Status</th>
                <th>Address</th>
                <th>Codec</th>
                <th>Channels</th>
                <th>Sample Rate</th>
                <th>Send Rate</th>
                <th>Size</th>
                <th>Fill</th>
                <th>Jitter</th>
                <th>Normal</th>
                <th>Underruns</th>
                <th>Overruns</th>
                <th>Normal</th>
                <th>PCM Tone</th>
                <th>Sine</th>
                <th>Noise</th>
                <th>Underruns</th>
                <th>Packets</th>
                <th>Bytes</th>
                <th>Lost</th>
                <th>Malformed</th>
                <th>Probation</th>
                <th>Reordered</th>
                <th>Overruns</th>
                <th>Stream Restarted</th>
                <th>SSRC Switches</th>
                <th>Seq Errors</th>
                <th>Codec Switches</th>
                <th>Unknown PT</th>
                <th>Packets</th>
                <th>Bytes</th>
                <th>Dropped</th>
                <th>RX Queue Pos</th>
                <th>TX Queue Pos</th>
                <th>Packet Queue Len</th>
              </tr>
              </thead>
              <tbody>
                {info.data ? info.data.map((stream, index) => (<tr>
                  <td>{index}</td>
                  {stream.map((item => (<td>{item}</td>)))}
                </tr>)) : <></>}
              </tbody>
            </table>
          </Panel>
        </Row>
      : pageType === 'system' ?
        <Row spacing={sitePadding}>
          <Panel title="System">
            <h3>Hardware</h3>
            <Table rows={system.hardware.memory.filter((item) => [
              "Total Memory:",
              "Free Memory:",
              "Mixer App:",
              "Call Control App:",
            ].indexOf(item.name) !== -1).map((v) => [v.name, v.value])}/>

            <hr/>
            <h3>Health</h3>
            <Table rows={system.health.filter((item) => [
              "OS:",
              "Version:",
              /*"Clock Status:",*/
              "System Time:",
              "Uptime:",
              "Container Uptime:"
            ].indexOf(item.name) !== -1).map((v) => {
              switch (v.name) {
                case "Uptime:":
                  v.name = "Kernel Uptime:";
                  break;
                /*case "Clock Status:":
                  if (v.value === "Error ()") v.value = "Not monitored";
                  break;*/
                default:
                  break;
              }

              return [v.name, v.value];
            })}/>
          </Panel>
        </Row>
      :
        <></>
      }
    </>);
  }

  return (
    <Switch>
      <Route exact path={path}>
        <RenderInformation/>
      </Route>
      <Route path={`${path}/:id`}>
        <RenderStudioInformation/>
      </Route>
    </Switch>
  );
};

export { InfoPage };
