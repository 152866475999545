import React, { useState, useEffect } from 'react';
import ReactMarkdown from "react-markdown";
import { Redirect, Switch, Link, Route, useRouteMatch, useParams } from 'react-router-dom';
import { Row, Panel, Table, Button, Input, Select, Toggle, InfoTooltip as UICInfoTooltip } from '@telosalliance/ui-core';
import { RequestAPI, RequestMethods, LoadIndicator, ValidateEmail, Breadcrumb, Warnings, InfoTooltip, _mergeArray } from '../Utils';
import { cloneDeep as _clone } from 'lodash';
import { alert as Alert, confirm as Confirm } from "@telosalliance/ui-core-framework"
import md5 from 'md5'

const AccountPage = ({ history, sitePadding, isLoggedIn, helpContext, warnings }) => {
  const Info = { variant: 'info' };
  const Warning = { variant: 'warning' };
  const Error = { variant: 'error' }; //Alert, Confirm

  const AUTH_RESULTS = {
    AUTH_SUCCESS:                     0,
    AUTH_INVALID_CREDENTIALS:         1,
    AUTH_NOT_AUTHORIZED:              2,
    AUTH_EMAIL_CONFIRMATION_REQUIRED: 3,
    AUTH_SERVICE_UNAVAILABLE:         4,
    AUTH_INVALID_USERNAME:            5,
    AUTH_USER_NOT_FOUND:              6,
    AUTH_USER_NOT_LOCAL:              7,
    AUTH_USER_ALREADY_EXISTS:         8,
    AUTH_OPERATION_FAILED:            9,
    AUTH_EMAIL_EXPECTED:              10,
    AUTH_CONFIRMATION_NOT_REQUIRED:   11
  };

  function createPasswordHash(username, realm, password) {
    return md5(String(username).toLowerCase() + ':' + realm + ':' + password);
  }

  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,30}$/;
  const passwordRequirements = '1) Password must be between 8 and 30 characters.\n\n' +
                               '2) Password must contain at least one uppercase, or capital, letter (ex: A, B, etc.)\n\n' +
                               '3) Password must contain at least one lowercase letter.\n\n' +
                               '4) Password must contain at least one number digit (ex: 0, 1, 2, 3, etc.)\n\n';

  const { path } = useRouteMatch();

  function RenderAccountsPage() {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    function refreshUsers() {
      setLoading(true);
      RequestAPI('/licensing/auth/users', null, null, (data) => {
        setLoading(false);
        if (Array.isArray(data.users)) setUsers(data.users);
      });
    }

    function deleteUser(username) {
      setLoading(true);
      RequestAPI('/licensing/auth/user', {username: username}, RequestMethods.DELETE, (data) => {
        setLoading(false);

        if (data.result) Alert('Cannot delete user, error code "' + data.reason + '"', Error);
        else refreshUsers();
      });
    }

    useEffect(() => { refreshUsers(); }, []);

    return ( !isLoggedIn ? <Redirect to="/"/> : <>
      <LoadIndicator open={loading}/>
      <Breadcrumb item="User Management"/>
      <Warnings value={warnings}/>

      <Row spacing={sitePadding}>
        <Panel title="Accounts">
          <Table
            className="accountsList"
            headers={[
              <>User<InfoTooltip source={helpContext} path="users/accounts-user"/></>,
              <>Type<InfoTooltip source={helpContext} path="users/accounts-type"/></>,
              ""]}
            rows={users.map((user) => {
              return [
                user.username,
                user.localAccount ? 'Local' : 'Global',
                <>
                  <Link to={path + '/account/edit/' + encodeURI(user.username)}><Button>Edit</Button></Link>&nbsp;
                  <Button onClick={ async () => {
                    if (await Confirm('This will delete user "' + user.username + '". Continue?', Warning)) deleteUser(user.username);
                  }}>Delete</Button>
                </>
              ];
            }).concat([[
              <></>,
              <></>,
              <Link to={path + '/account/add'}><Button color="blue">Add</Button></Link>
            ]])}/>
        </Panel>
      </Row>
    </>);  
  }

  function RenderAddAccountPage() {
    const [username, setUsername] = useState('user');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    function addLocalUser(e) {
      e.preventDefault();

      if (username.trim() === '') {
        Alert('Username is a required field!', Warning);
        return;
      }

      setLoading(true);
      RequestAPI('/licensing/auth/info', null, null, (info) => {
        let passwordHash = createPasswordHash(username, info.realm, password);
        RequestAPI('/licensing/auth/local_user', {username: username, passwordHash: passwordHash}, RequestMethods.POST, (data) => {
          setLoading(false);

          if (data.result) Alert('Cannot add user, error code "' + data.reason + '"', Error);
          else history.replace('/users');
        });
      });
    }

    function addGlobalUser(e) {
      e.preventDefault();

      if (!ValidateEmail(email)) {
        Alert('Invalid email address! Please verify your email address and try again!', Warning);
        return;
      }

      setLoading(true);
      RequestAPI('/licensing/auth/user', {username: email}, RequestMethods.POST, (data) => {
        setLoading(false);

        if (data.result) Alert('Cannot add user, error code "' + data.reason + '"', Error);
        else history.replace('/users');
      });
    }

    return ( !isLoggedIn ? <Redirect to="/"/> : <>
      <LoadIndicator open={loading}/>
      <Breadcrumb item="Add Account" path={[
        { link: "/users", text: "User Management" }
      ]}/>
      <Warnings value={warnings}/>

      <Row spacing={sitePadding}>
      <Panel title="Local Account">
          <form onSubmit={addLocalUser}>
            <Table
              className="accountFields"
              rows={[
                ["Username", <><input value={username} class="uic-input" required="required" onChange={(ev) => setUsername(ev.currentTarget.value)}/><InfoTooltip source={helpContext} path="users/accounts-add-local-username"/></>],
                ["Password", <><Input value={password} type="password" onChange={(value) => setPassword(value)}/><InfoTooltip source={helpContext} path="users/accounts-add-local-password"/></>]
              ]}/>
              <div className="btn-row">
                <Button color="blue" type="submit">Add</Button>
              </div>
            </form>
        </Panel>
        <Panel title="Global Account">
          <form onSubmit={addGlobalUser}>
            <Table
              className="accountFields"
              rows={[
                ["Email", <><Input value={email} onChange={(value) => setEmail(value)}/><InfoTooltip source={helpContext} path="users/accounts-add-global-email"/></>],
              ]}/>
              <div className="btn-row">
                <Button color="blue" type="submit">Add</Button>
              </div>
            </form>
        </Panel>
      </Row>
    </>);  
  }

  function RenderEditAccountPage() {
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({invalid: false, username: '', local: true});
    const [passwordRequirementsInfo, setPasswordRequirementsInfo] = useState({info: helpContext["users/accounts-edit-password"]});

    let { id } = useParams();

    function updateUser(e) {
      e.preventDefault();

      if (oldPassword === password) {
        Alert('Old password matches the new password!', Warning);
        return;
      }

      if (!user.localAccount) {
        if (!password.match(passwordRegex)) {
          Alert(<>Invalid password!<br/><br/><ReactMarkdown source={passwordRequirements}/></>, Warning);
          return;
        }  
      }

      setLoading(true);
      RequestAPI('/licensing/auth/info', null, null, (info) => {
        let oldPasswordHash = createPasswordHash(id, info.realm, oldPassword);
        let newPasswordHash = createPasswordHash(id, info.realm, password);
        RequestAPI('/licensing/auth/password', {username: id, oldPasswordHash: oldPasswordHash, newPasswordHash: newPasswordHash}, RequestMethods.POST, (data) => {
          setLoading(false);

          switch (data.result) {
            case AUTH_RESULTS.AUTH_SUCCESS:
              history.push('/users');
              break;
            case AUTH_RESULTS.AUTH_INVALID_CREDENTIALS:
              Alert('Cannot change password, invalid old password!', Error);
              break;
            default:
              Alert('Cannot change password, error code "' + data.reason + '"', Error);
              break;
          }
        });
      });
    }

    useEffect(() => {
      setLoading(true);

      let found = false;
      RequestAPI('/licensing/auth/users', null, null, (data) => {
        if (data.users) data.users.map(user => {
          if (user.username === id) {
            if (!user.localAccount) { //if global user
              let info = passwordRequirementsInfo.info ? passwordRequirementsInfo.info + '\n\n' : '';
              setPasswordRequirementsInfo({info: info + passwordRequirements});
            }
            setUser({invalid: false, username: user.username, localAccount: user.localAccount});
            found = true;
          }
          return null;
        });
        setLoading(false);
        if (!found) setUser(_mergeArray({}, user, {invalid: true}));
      });
    }, []);

    return ( !isLoggedIn || user.invalid ? <Redirect to="/users"/> : <>
      <LoadIndicator open={loading}/>
      <Breadcrumb item="Edit Account" path={[
        { link: "/users", text: "User Management" }
      ]}/>
      <Warnings value={warnings}/>

      <Row spacing={sitePadding}>
      <Panel title="Edit Account">
          <form onSubmit={updateUser}>
            <Table
              className="accountFields"
              rows={[
                ["Username", <>{user.username}</>],
                ["Old Password", <><Input value={oldPassword} autoFocus="true" type="password" onChange={(value) => setOldPassword(value)}/><InfoTooltip source={helpContext} path="users/accounts-edit-old-password"/></>],
                ["Password", <><Input value={password} type="password" onChange={(value) => setPassword(value)}/><InfoTooltip source={passwordRequirementsInfo} path="info"/></>]
              ]}/>
              <div className="btn-row">
                <Button color="blue" type="submit">Update</Button>
              </div>
            </form>
        </Panel>
      </Row>
    </>);
  }

  function RenderResetPage() {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');

    function resetPassword(e) {
      e.preventDefault();

      if (!ValidateEmail(email)) {
        Alert('Invalid email address! Please verify your email address and try again!', Warning);
        return;
      }

      setLoading(true);
      RequestAPI('/licensing/auth/reset/request', { username: email }, RequestMethods.POST, (data) => {
        setLoading(false);

        if (data.result) {
          switch (data.result) {
            case AUTH_RESULTS.AUTH_OPERATION_FAILED:
              Alert('Cannot reset password! Please verify your email address and try again!', Error);
              break;
            default:
              Alert('Cannot reset password, error code "' + data.reason + '"', Error);
              break;
          }
        } else history.push(path + '/reset/confirm/' + encodeURI(email));
      });
    }

    return (
      <>
        <LoadIndicator open={loading}/>
  
        <Row justify="center" style={{ paddingTop: sitePadding }} className="login">
          <Panel title="Reset Password">
            <form onSubmit={resetPassword} autocomplete="off">
              <Table
                columnWidths={["*", "200px"]}
                rows={[
                  ["Email", <Input value={email} onChange={setEmail}/>],
                  [<div className="buttons">
                      <Link to="/"><Button>Cancel</Button></Link>
                      <Button color="blue" type="submit">Submit</Button>
                    </div>],
                ]}
              />
            </form>
          </Panel>
        </Row>
      </>
    );
  }

  function RenderResetConfirmationPage() {
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');

    let { email } = useParams();
    function resendEmail() {
      setLoading(true);
      RequestAPI('/licensing/auth/reset/request', { username: email }, RequestMethods.POST, (data) => {
        setLoading(false);

        if (data.result) Alert('Cannot resend email, error code "' + data.reason + '"', Error);
        else Alert('A password reset email has been resent.', Info);
      });
    }

    function confirmReset(e) {
      e.preventDefault();

      if (!code) {
        Alert('Code is a required field!', Warning);
        return;
      }

      if (!password.match(passwordRegex)) {
        Alert(<>Invalid password!<br/><br/><ReactMarkdown source={passwordRequirements}/></>, Warning);
        return;
      }

      setLoading(true);
      RequestAPI('/licensing/auth/info', null, null, (info) => {
        let oldPasswordHash = createPasswordHash(email, info.realm, code);
        let passwordHash = createPasswordHash(email, info.realm, password);
        RequestAPI('/licensing/auth/reset', { username: email, oldPasswordHash: oldPasswordHash, newPasswordHash: passwordHash }, RequestMethods.POST, (data) => {
          setLoading(false);

          if (data.result) {
            switch (data.result) {
              case AUTH_RESULTS.AUTH_NOT_AUTHORIZED:
                Alert('Cannot reset password, invalid verification code!', Error);
                break;
              default:
                Alert('Cannot reset password, error code "' + data.reason + '"', Error);
                break;
            }
          } else (async () => {
            await Alert('The password has been reset. Please login with the new password.', Info);
            history.replace('/');
          })();
        });
      });
    }

    return (
      <>
        <LoadIndicator open={loading}/>
  
        <Row justify="center" style={{ paddingTop: sitePadding }} className="resetpwd">
          <Panel title="Reset Password">
            <form onSubmit={confirmReset} autocomplete="off">
              <Table
                columnWidths={["*", "200px"]}
                rows={[
                  [<div className="text">Thank you! A password reset email has been sent to "{email}". Please check your email for the verification code.</div>],
                  ["Code", <Input value={code} onChange={setCode}/>],
                  ["New Password", <>
                    <Input type="password" value={password} onChange={setPassword}/>
                    <UICInfoTooltip children={passwordRequirements}/>
                  </>],
                  [<div className="buttons">
                      <Button onClick={() => { resendEmail(); }}>Resend</Button>
                      <Button color="blue" type="submit">Continue</Button>
                    </div>],
                ]}
              />
            </form>
          </Panel>
        </Row>
      </>
    );
  }

  function RenderRegistrationPage() {
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState([]);
    const [formData, setFormData] = useState({});

    function linkTo(e, link) {
      e.preventDefault();
      switch (link) {
        case 'eula':
        case 'benefits':
          setLoading(true);
          RequestAPI('/licensing/auth/form/' + link, null, null, (data) => {
            setLoading(false);
            if (Array.isArray(data)) {
              Alert(<div className={`uic-infoTooltip register-${link}`}>{data.filter((field) => field.type === 'markdown').reduce((a, field) => {
                field.text.map((v) => a.push(<ReactMarkdown source={v} linkTarget="_blank"/>));
                return a;
              }, [])}</div>, {okButtonProps: { text: 'Close' }});
            }
          });
          break;
        default:
          break;
      }
    }

    function replaceLink(text, link) {
      if (link) {
        let m = text.match(/^([^<]*)<link>([^<]+)<\/link>([^<]*)$/);
        if (m) text = <>{m[1]}<Link onClick={(e) => { linkTo(e, link); }}>{m[2]}</Link>{m[3]}</>;
      }
      return text;
    }

    function formatLabel(field) {
      return <>{replaceLink(field.label, field.link)}{field.required ? '*' : ''}</>;
    }

    function updateFormData(name, value) {
      setFormData(_mergeArray({}, formData, { [name]: value }));
    }

    function registerAccount(e) {
      e.preventDefault();

      for (let i in form) {
        let field = form[i];
        if (field.required && !formData[field.name]) {
          switch (field.name) {
            case 'eula':
              Alert('You must accept EULA to register!', Warning);
              break;
            default:
              Alert('"' + field.label + '" is a required field!', Warning);
              break;
          }
          return;
        }

        switch (field.type) {
          case 'username':
            if (!ValidateEmail(formData[field.name])) {
              Alert("Invalid email address! Please verify your email address and try again!", Warning);
              return;
            }
            break;
          case 'password':
            if (!formData[field.name].match(passwordRegex)) {
              Alert(<>Invalid password!<br/><br/><ReactMarkdown source={passwordRequirements}/></>, Warning);
              return;
            }

            let rpt = field.name + '-repeat';
            if (formData.hasOwnProperty(rpt) && formData[field.name] !== formData[rpt]) {
              Alert('Repeated passwords dont\'t match!', Warning);
              return;
            }
            break;
          default:
            break;
        }
      }

      RequestAPI('/licensing/auth/info', null, null, (info) => {
        let data = _clone(formData);
        let username, passwordHash;
        for (let i in form) {
          let field = form[i];
          switch (field.type) {
            case 'eula':
              data[field.name] = String(data[field.name]);
              break;
            case 'username':
              username = data[field.name];
              break;
            case 'password':
              let u = form.filter(f => f.type === 'username');
              if (u && u.length) {
                passwordHash = createPasswordHash(data[u[0].name], info.realm, data[field.name]);
                delete data[field.name];
              }
              break;
            case 'password-repeat':
              delete data[field.name];
              break;
            default:
              break;
          }
        }

        delete data.username;

        let request = {
          username: username,
          passwordHash: passwordHash,
          fields: data
        }
        
        setLoading(true);
        RequestAPI('/licensing/auth/register/user', request, RequestMethods.POST, (data) => {
          setLoading(false);

          if (data.result) Alert('Cannot register account, error code "' + data.reason + '"', Error);
          else {
            (async () => {
              await Alert('Thank you! A confirmation email has been sent.', Info);
              history.push(path + '/register/confirm/' + encodeURI(username));
            })();
          }
        });
      });
    }

    useEffect(() => {
      setLoading(true);
      RequestAPI('/licensing/auth/form/register', null, null, (data) => {
        setLoading(false);
        if (Array.isArray(data)) {
          let values = {};
          data.map((field) => {
            if (field.hasOwnProperty('value')) values[field.name] = field.value;
            //if (field.name && !values[field.name]) values[field.name] = field.name;
            return null;
          });
          //values['username'] = '123@abc.info';
          //values['password'] = '123abcABC';
          //values['password-repeat'] = '123abcABC';
          setForm(data);
          setFormData(values);
        }
      });
    }, []);

    return (
      <>
        <LoadIndicator open={loading}/>

        <Row justify="center" style={{ paddingTop: sitePadding }} className="register">
          <Panel title="Register">
            <form onSubmit={registerAccount} autocomplete="off">
              <Table
                columnWidths={["*", "350px"]}
                rows={form.map((field) => {
                  let row;
                  switch (field.control) {
                    case "input":
                      row = [
                        <label for={field.name}>{formatLabel(field)}</label>,
                        <>
                          <input class="uic-input" type={field.type === "password" || field.type === "password-repeat" ? "password" : "text"} id={field.name} name={field.name} value={formData[field.name]} required={field.required} disabled={field.disabled} onChange={(ev) => { updateFormData(field.name, ev.currentTarget.value); }}/>
                          {field.type === "password" || field.type === "password-repeat" ? <UICInfoTooltip children={passwordRequirements}/> : <></>}
                        </>
                      ]
                      break;
                    case "checkbox":
                      row = [<label for={field.name}>{formatLabel(field)}</label>, <Toggle id={field.name} name={field.name} checked={formData[field.name]} onChange={(value) => { updateFormData(field.name, value); }}/>]
                      break;
                    case "select":
                      row = [<label for={field.name}>{formatLabel(field)}</label>, <Select id={field.name} name={field.name} value={formData[field.name]} onChange={(value) => { updateFormData(field.name, value); }} options={field.options}/>];
                      break;
                    case "markdown":
                      row = [<div class="text"><ReactMarkdown source={field.text}/></div>]
                      break;
                    case "p":
                      row = [<div class="text">{replaceLink(field.text, field.link)}</div>]
                      break;
                    default:
                      row = [];
                      break;
                  }

                  return row;
                }).concat([
                  [<small>* Required field</small>],
                  [<div className="buttons">
                      <Link to="/"><Button>Cancel</Button></Link>
                      <Button color="blue" type="submit">Register</Button>
                    </div>],
                ])}
              />
            </form>
          </Panel>
        </Row>
      </>
    );
  }

  function RenderRegistrationConfirmationPage() {
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState('');

    let { username } = useParams();
    function resendEmail() {
      setLoading(true);
      RequestAPI('/licensing/auth/register/resend', { token: '', username: username }, RequestMethods.POST, (data) => {
        setLoading(false);
        
        if (data.result) Alert('Cannot resend email, error code "' + data.reason + '"', Error);
        else Alert('A confirmation email has been resent.', Info);
      });
    }

    function confirmRegistration(e) {
      e.preventDefault();

      setLoading(true);
      RequestAPI('/licensing/auth/info', null, null, (info) => {
        let confirmationCodeHash = createPasswordHash(username, info.realm, code);
        RequestAPI('/licensing/auth/confirm/user', { username: username, confirmationCodeHash: confirmationCodeHash }, RequestMethods.POST, (data) => {
          setLoading(false);

          if (data.result) {
            switch (data.result) {
              case AUTH_RESULTS.AUTH_INVALID_CREDENTIALS:
                Alert('Cannot confirm email, invalid verification code!', Error);
                break;
              default:
                Alert('Cannot confirm email, error code "' + data.reason + '"', Error);
                break;
            }
          } else  (async () => {
            await Alert('Confirmation successful. Please login with your username and password.', Info);
            history.replace('/');
          })();
        });
      });
    }

    return (
      <>
        <LoadIndicator open={loading}/>
  
        <Row justify="center" style={{ paddingTop: sitePadding }} className="login">
          <Panel title="Confirm Email">
            <form onSubmit={confirmRegistration} autocomplete="off">
              <Table
                columnWidths={["*", "200px"]}
                rows={[
                  [<div className="text">Please check your email for the verification code.</div>],
                  ["Code", <Input value={code} onChange={setCode}/>],
                  [<div className="buttons">
                      <Button onClick={() => { resendEmail(); }}>Resend</Button>
                      <Button color="blue" type="submit">Continue</Button>
                    </div>],
                ]}
              />
            </form>
          </Panel>
        </Row>
      </>
    );
  }

  return (<Switch>
    <Route exact path={path}>
      <RenderAccountsPage/>
    </Route>
    <Route exact path={`${path}/account/add`}>
      <RenderAddAccountPage/>
    </Route>
    <Route exact path={`${path}/account/edit/:id`}>
      <RenderEditAccountPage/>
    </Route>
    <Route exact path={`${path}/reset`}>
      <RenderResetPage/>
    </Route>
    <Route exact path={`${path}/reset/confirm/:email`}>
      <RenderResetConfirmationPage/>
    </Route>
    <Route exact path={`${path}/register`}>
      <RenderRegistrationPage/>
    </Route>
    <Route exact path={`${path}/register/confirm/:username`}>
      <RenderRegistrationConfirmationPage/>
    </Route>
  </Switch>);
};

export { AccountPage };
