import React, { useState, useEffect } from "react";
import { Row, Panel, Table, Button, Input } from "@telosalliance/ui-core";
import { RequestAPI, LoadIndicator, Breadcrumb, Warnings, RequestMethods, InfoTooltip } from "../Utils";
import { alert as Alert, confirm as Confirm } from "@telosalliance/ui-core-framework";

const LicensingServerPage = ({ sitePadding, helpContext, warnings }) => {
  const Warning = { variant: 'warning' }; //Alert, Confirm

  const [loading, setLoading] = useState(false);
  const [servers, setServers] = useState([]);
  const [address, setAddress] = useState('');

  function refreshServers() {
    RequestAPI('/licensing/servers', null, null, data => {
      setLoading(false);
      setServers(data);
    });
  }

  function addServer(e) {
    e.preventDefault();

    if (address.trim() === '') {
      Alert('Server address is a required field!', Warning);
      return;
    }

    setLoading(true);
    RequestAPI('/licensing/servers', { address: address }, RequestMethods.POST, data => {
      if (data && data.code && data.error_message) Alert('Cannot add server: ' + data.error_message, Warning);
      else setAddress('');
      
      refreshServers();
    });
  }

  function removeServer(address) {
    setLoading(true);
    RequestAPI('/licensing/servers', { address: address }, RequestMethods.DELETE, data => {
      if (data && data.code && data.error_message) Alert('Cannot remove server: ' + data.error_message, Warning);
      refreshServers();
    });
  }

  useEffect(() => {
    setLoading(true);
    refreshServers();
  }, []);

  return (
    <>
      <LoadIndicator open={loading}/>
      <Breadcrumb item="License Server Management"/>
      <Warnings value={warnings} exclude={["licensing"]}/>

      <h1>License Server Management</h1>
      <br/>

      <Row spacing={sitePadding}>
        <Panel title="Add Server" className="addServer">
          <form onSubmit={addServer}>
            <Table
              rows={[
                ["Server address", <><Input value={address} onChange={(value) => setAddress(value)}/><InfoTooltip source={helpContext} path="license-servers/add-server"/></>]
              ]}/>
            <div className="btn-row">
              <Button color="blue" type="submit">Add</Button>
            </div>
          </form>
        </Panel>
        {servers.length ? 
          <Panel title="Servers">
            <Table
              alignLabelsLeft={true}
              className="serversList"
              rows={servers.map(server => [
                <h3>{server}</h3>,
                <Button color="blue" onClick={async () => {
                  if (await Confirm('This will remove the server address "' + server + '". Continue?', Warning)) removeServer(server)}
                }>Remove</Button>])}/>
          </Panel>
        : <></> }
      </Row>
    </>
  );
}

export { LicensingServerPage };
