import React, { useState, useEffect } from "react";
import { Prompt } from "react-router-dom";
import { clone as _clone } from "lodash"
import { Row, Panel, Table, Toggle, Select, Button, Slider } from "@telosalliance/ui-core";
import { RequestAPI, RequestMethods, Breadcrumb, Warnings, LoadIndicator, InfoTooltip, _mergeArray } from '../Utils';

const apiUrl = '/config/hybrid';

const HybridPage = ({ sitePadding, helpContext, warnings }) => {
  const agcs = ["Off","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16"];
  const gates = [
    {id: 1, name: "Off"},
    {id: 2, name: "Normal"},
    {id: 3, name: "Aggressive"}
  ];
  const eq_modes = [
    {id: 0, name: "Off"},
    {id: 1, name: "Fixed"},
    {id: 2, name: "Adaptive"}
  ];
  const eq_gains = [-8,-6,-4,-2,0,2,4,6,8];
  const duck_gains = [
    {id: 0, name: "0dB (Off/Full Duplex)"},
    {id: -6, name: "6dB"},
    {id: -12, name: "12dB"},
    {id: -15, name: "15dB"},
    {id: -16, name: "Max dB (Half Duplex)"}
  ];
  const [loading, setLoading] = useState(false);
  const [unsaved, setUnsaved] = useState(false);
  const [hybrid, setHybrid] = useState({
    agcs: agcs,
    gates: gates,
    eq_modes: eq_modes,
    eq_gains: eq_modes,
    duck_gains: duck_gains
  });

  //function formatDb(db) { return (db >= 0 ? '+' : '') + db + '.0dB';}

  function refreshHybrid() {
    RequestAPI(apiUrl, null, null, data => {
      setLoading(false);
      data.agcs = agcs;
      data.gates = gates;
      data.eq_modes = eq_modes;
      data.eq_gains = eq_gains;
      data.duck_gains = duck_gains;
      setHybrid(data);
    });
  }
  function updateHybrid(value) {
    setHybrid(_mergeArray({}, hybrid, value));
    setUnsaved(true);
  }
  function saveHybrid(e) {
    e.preventDefault();

    setUnsaved(false);

    let hybridData = _clone(hybrid);
    delete hybridData.agcs;
    delete hybridData.gates;
    delete hybridData.eq_modes;
    delete hybridData.eq_gains;
    delete hybridData.duck_gains;

    setLoading(true);
    RequestAPI(apiUrl, hybridData, RequestMethods.POST, refreshHybrid);
  }

  useEffect(() => {
    setLoading(true);
    refreshHybrid();
  }, []);

  return (
    <>
      <LoadIndicator open={loading}/>
      <Breadcrumb item="Call Audio Processing"/>
      <Warnings value={warnings}/>

      <h1>Call Audio Processing</h1>
      <br />
      <Row spacing={sitePadding}>
        <Panel title="Settings">
          <Prompt when={unsaved} message="Call audio processing data has been changed but haven't been saved. Continue?"/>
  
          <form onSubmit={saveHybrid}>
            <Table
              rows={[
                ["Enable Call Audio Processing", <><Toggle checked={hybrid.dyn_active} onChange={(value) => { updateHybrid({dyn_active: value}); }} /><InfoTooltip source={helpContext} path="hybrid/enabled"/></>],
                ["Receive AGC", <><Select value={hybrid.agc_preset} onChange={(value) => { updateHybrid({agc_preset: value}); }}>{hybrid.agcs.map((id) => <option value={id === 'Off' ? 1 : id}>{id}</option>)}</Select><InfoTooltip source={helpContext} path="hybrid/agc"/></>],
                ["Noise Gate", <><Select value={hybrid.gate_preset} onChange={(value) => { updateHybrid({gate_preset: value}); }}>{hybrid.gates.map(({id, name}) => <option value={id}>{name}</option>)}</Select><InfoTooltip source={helpContext} path="hybrid/gate"/></>],
                ["Receive EQ Mode", <><Select value={hybrid.eq_mode} onChange={(value) => { updateHybrid({eq_mode: value}); }}>{hybrid.eq_modes.map(({id, name}) => <option value={id}>{name}</option>)}</Select><InfoTooltip source={helpContext} path="hybrid/eq-mode"/></>],
                ["Additional Low EQ", <><Slider value={hybrid.eq_low_gain} onChange={(value) => { updateHybrid({eq_low_gain: Math.round(value)}); }} min={-8} max={8} step={1} withInput="true"/><InfoTooltip source={helpContext} path="hybrid/eq-low-gain"/></>],
                ["Additional High EQ", <><Slider value={hybrid.eq_high_gain} onChange={(value) => { updateHybrid({eq_high_gain: Math.round(value)}); }} min={-8} max={8} step={1} withInput="true"/><InfoTooltip source={helpContext} path="hybrid/eq-high-gain"/></>],
                ["Caller Ducking Level", <><Select value={hybrid.ducking_gain} onChange={(value) => { updateHybrid({ducking_gain: value}); }}>{hybrid.duck_gains.map(({id, name}) => <option value={id}>{name}</option>)}</Select><InfoTooltip source={helpContext} path="hybrid/duck-gain"/></>],
              ]}/>
            <div className="btn-row">
              <Button color="blue" type="submit">Save</Button>
            </div>
          </form>
        </Panel>
      </Row>
    </>
  );
}

export { HybridPage };