import React, { useState, useEffect, useRef } from "react";
import { Row, Panel, Table, Button, Modal } from "@telosalliance/ui-core";
import { RequestAPI, LoginState, RequestMethods, RequestPrefix, Breadcrumb, Warnings, LoadIndicator, InfoTooltip } from '../Utils';
import { alert as Alert, confirm as Confirm } from "@telosalliance/ui-core-framework";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Redirect } from "react-router-dom";

const rebootSeconds = 10;

const BackupPage = ({ sitePadding, helpContext, warnings }) => {
  const Warning = { variant: 'warning' }; //Alert, Confirm

  const [loading, setLoading] = useState(false);
  const [rebootCounter, setRebootCounter] = useState(null);
  const rebootCounterRef = useRef(rebootCounter);
  rebootCounterRef.current = rebootCounter;

  let refs = {};
  let interval = null;

  function setRebootTimer(seconds = null) {
    if (interval) clearInterval(interval);
    if (seconds) {
      setRebootCounter(seconds)
      interval = setInterval(rebootTimer, 1000);
    } else setRebootCounter(null);
  }

  function rebootTimer() {
    setRebootCounter(rebootCounterRef.current - 1)
  }

  function renderRebootProgress() {
    return (
      rebootCounter !== null && (
        <Modal open={true}>
          <div>Restarting VXs, please wait ({rebootCounter}s)...</div>
          <br />
          <LinearProgress />
        </Modal>
      )
    );
  }

  async function restoreSettings(fileInput) {
    const formData = new FormData(fileInput.form);
    formData.set('network', true);

    const formatMessages = (list) => {
      let result = [];
      list.map((msg) => {
        result.push(<>{msg}</>);
        result.push(<br/>);
        return null;
      });
      return result;
    }
  
    setLoading(true);
    RequestAPI('/upload/backup', formData, RequestMethods.POST, (result) => {
      fileInput.value = '';
      RequestAPI('/config/backup', null, null, (data) => {
        setLoading(false);
        if (data.warnings && data.warnings.length) Alert(formatMessages(data.warnings), Warning);
        else setRebootTimer(rebootSeconds);
        //else Alert(formatMessages(data.messages.concat(['', 'Please restart the VXs container'])), Info);
      });
    });
  }

  useEffect(() => {
    LoginState(() => {});
  }, []);

  return (
    <>
      {renderRebootProgress()}
      {rebootCounter !== null && rebootCounter <= 0 && <Redirect to="/"/>}

      <LoadIndicator open={loading}/>
      <Breadcrumb item="Configuration Management"/>
      <Warnings value={warnings}/>

      <h1>Configuration Management</h1>
      {<h4>Note: The system will automatically restart after the configuration is loaded/reset.</h4>}
      <br/>
      
      <Row spacing={sitePadding}>
        <Panel title="Backup/Restore">
          <h3>Backup</h3>
          <Table
            rows={[
              ["Save settings to an XML file",
              <form method="GET" action={RequestPrefix() + "/api/download/ConfigBackupXML"}>
                <Button color="blue" type="submit">Backup Settings</Button>
                <InfoTooltip source={helpContext} path="backup/backup"/>
              </form>]
            ]}/>

          <hr/>
          <h3>Restore</h3>
          <form onSubmit={ev => ev.preventDefault()}>
            <input ref={input => refs.restore = input} type="file" name="uploadControl" className="uic-inputFile" onChange={async (e) => {
              let target = e.currentTarget;
              if (await Confirm(<>The current settings will be lost and VXs will restart!<br/><br/>Are you sure you want to continue?</>, Warning)) restoreSettings(target);
              //if (await Confirm(<>The current settings will be lost!<br/><br/>Are you sure you want to continue?</>, Warning)) restoreSettings(target);
            }}/>
            <Table
              rows={[
                ["Restore previously saved settings",<>
                  <Button color="blue" onClick={() => {refs.restore.click()}}>Restore Settings</Button>
                  <InfoTooltip source={helpContext} path="backup/restore"/>
                </>],
              ]}/>
          </form>
        </Panel>
      </Row>
    </>
  );
}

export { BackupPage };
