import React, { useState, useEffect } from "react";
import { Prompt, Route, Switch, Link, useRouteMatch, useParams } from "react-router-dom";
import { Row, Panel, Table, Input, Toggle, Select, Button } from "@telosalliance/ui-core";
import { alert as Alert, confirm as Confirm } from "@telosalliance/ui-core-framework"
import { RequestAPI, RequestMethods, Breadcrumb, Notification, Warnings, LoadIndicator, InfoTooltip,  _mergeArray } from '../Utils';
import { merge as _merge, cloneDeep as _clone } from 'lodash';

const apiUrl = '/shows';
const addressbookUrl = 'addressbook';
const addPath = 'add-show';

const ShowsPage = ({ history, sitePadding, helpContext, warnings }) => {
  const Warning = { variant: 'warning' }; //Alert, Confirm
  const UnsavedMessage = 'Show data has been changed but haven\'t been saved. Continue?';

  const { path } = useRouteMatch();

  function RenderShows() {
    const [loading, setLoading] = useState(false);
    const [shows, setShows] = useState([]);

    function refreshShows() {
      RequestAPI(apiUrl, null, null, data => {
        setLoading(false);
        setShows(data);
      });
    }

    function deleteShow(name) {
      setLoading(true);
      RequestAPI(apiUrl + '/' + name, null, RequestMethods.DELETE, refreshShows);
    }

    useEffect(() => {
      setLoading(true);
      refreshShows();
    }, []);

    return (<>
      <LoadIndicator open={loading}/>
      <Breadcrumb item="Shows Configuration"/>
      <Warnings value={warnings}/>

      <h1>Show Configuration</h1>
      <br />

      <Row spacing={sitePadding}>        
        <Panel title="Shows">
          <Table
            alignLabelsLeft={true}
            headers={[
              <>Name<InfoTooltip source={helpContext} path="shows/shows-name"/></>,
              <>Lines<InfoTooltip source={helpContext} path="shows/shows-lines"/></>,
              <>Active Studios<InfoTooltip source={helpContext} path="shows/shows-studios"/></>,
              ""]}
            rows={shows.map((show) => {
              let studiosList = show.studios.map(studio => studio.name);
              let studios = studiosList.length ? studiosList.join(', ') : 'None';

              return [
                show.name,
                show.lines,
                studios,
                <>
                  <Link to={path + '/' + show.id}><Button>Edit</Button></Link>&nbsp;
                  <Button disabled={show.studios.length !== 0} onClick={ async () => {
                    if (await Confirm('Deleting show "' + show.name + '" cannot be undone. Continue?', Warning)) deleteShow(show.id);
                  }}>Delete</Button>
                </>
            ]}).concat([[
              <></>,
              <></>,
              <></>,
              <Link to={path + '/' + addPath}><Button color="blue">Add</Button></Link>
            ]])} />
        </Panel>
      </Row>
    </>);
  }

  function RenderShowEditor() {
    const [loading, setLoading] = useState(false);
    const [unsaved, setUnsaved] = useState(false);
    const [editor, setEditor] = useState({data: {studios: [], lines: []}});
    
    function getShowID() { return editor.data.id; }

    function editShow(id) {
      let ringtones = [{id: 0, name: 'Default'}];
      for (let i = 1; i <= 9; i++) ringtones.push({id: i, name: 'Ringtone ' + i});

      RequestAPI('/features', null, null, licenses => {
        let faders = [{id: 0, name: 'Selectable'}];
        for (let i = 1; i <= licenses.max_faders; i++) faders.push({id: i, name: 'Fixed #' + i});

        RequestAPI(apiUrl, null, null, shows => {
          if (id === addPath) {
            let r = /^Show ([0-9]+)$/, m = 0;
            shows.map(show => {
              let n = show.name.match(r);
              if (n && Number(n[1]) > m) m = Number(n[1]);
              return null;
            });  
      
            let data = {
              lines: [],
              studios: [],
              servers: [],
              faders: faders,
              ringtones: ringtones
            };

            RequestAPI('/sip', null, null, (sip) => {
              setLoading(false);
      
              data.add = true;
              data.name = 'Show ' + (Number(m) + 1);
              data.servers = sip.servers;
              data.servers.unshift({});
              setEditor({data: data});
            });
          } else {
            let found = false;
            shows.map((show) => {
              if (show.id === Number(id)) found = true;
              return false;
            });

            if (found) RequestAPI(apiUrl + '/' + id, null, null, (data) => {
              setLoading(false);
              data.faders = faders;
              data.ringtones = ringtones;
              data.servers.unshift({});
              setEditor({data: data});
            }); else history.replace(path);
          }
        });
      });
    }
  
    function updateShow(value) {
      setEditor(_merge({}, editor, { data: value }));
      setUnsaved(true);
    }

    function saveShow(e) {
      e.preventDefault();
  
      if (editor.data.name === addPath) {
        Alert('Show name "' + editor.data.name + '" cannot be used! Please choose a different name.', Warning);
        return;
      }

      setUnsaved(false);
  
      if (editor.data.name.trim() === '') return;
      editor.data.lines = editor.data.lines.filter(line => !line.remove);
  
      setLoading(true);
      let id = getShowID();
      if (editor.data.add) {
        RequestAPI(apiUrl, editor.data, RequestMethods.POST, () => {
          RequestAPI(apiUrl, null, RequestMethods.GET, (shows) => {
            id = shows.filter(show => show.name === editor.data.name)[0].id;
            history.replace(path + '/' + id);
          });
        });
      } else {
        RequestAPI(apiUrl + '/' + id, editor.data, RequestMethods.POST, () => { editShow(id); });
      }
    }
  
    function addShowLine() {
      let lines = _clone(editor.data.lines);
      lines.push({add: true, shows: []});
      setEditor(_merge({}, editor, { data: { lines: lines }}));
      setUnsaved(true);
    }
    function updateShowLine(index, value) {
      let lines = _clone(editor.data.lines);
      lines[index] = _merge(lines[index], value);
      setEditor(_merge({}, editor, {data: { lines: lines }}));
      setUnsaved(true);
    }
    function deleteShowLine(index) {
      let lines = _clone(editor.data.lines);
      if (lines[index].add) lines.splice(index, 1); else lines[index].remove = true;
      setEditor(_mergeArray({}, editor, { data: { lines: lines}}));
      setUnsaved(true);
    }
  
    let { id } = useParams();
    useEffect(() => {
      setLoading(true);
      editShow(id);
    }, [id]);

    return (<>
      <LoadIndicator open={loading}/>
      <Breadcrumb item="Show" path={[
        { link: '/shows', text: 'Shows Configuration' }
      ]}/>
      <Warnings value={warnings}/>

      <h1>{editor.data.add !== true ? <>Show "{editor.data.name}"</> : <>Add Show</>}</h1>
      <br />
      <Row spacing={sitePadding}>
        <Panel title="Show" className="general">
          <Prompt when={unsaved} message={UnsavedMessage}/>

          <form onSubmit={saveShow}>
            <Notification visible={editor.data.studios.length}>Show is in use by studio(s) "{editor.data.studios.map(studio => studio.name).join(', ')}"<InfoTooltip source={helpContext} path="shows/in-use"/></Notification>

            <h3>Configuration</h3>
            <Table
              rows={[
                ["Name", <><Input autoFocus disabled={editor.data.studios.length} value={editor.data.name} onChange={(value) => { updateShow({name: value}); }}/><InfoTooltip source={helpContext} path="shows/show-name"/></>],
              ]}/>
            <br/>

            <h3>Lines</h3>        
            <Table
              alignLabelsLeft={true}
              headers={[
                "",
                <>Name<InfoTooltip source={helpContext} path="shows/lines-name"/></>,
                <>Extension<InfoTooltip source={helpContext} path="shows/lines-extension"/></>,
                <>Server<InfoTooltip source={helpContext} path="shows/lines-server"/></>,
                <>Channel<InfoTooltip source={helpContext} path="shows/lines-channel"/></>,
                <>Block All?<InfoTooltip source={helpContext} path="shows/lines-block-all"/></>,
                <>Ringer<InfoTooltip source={helpContext} path="shows/lines-ringer"/></>,
                ""]}
              rows={editor.data.lines.map((line, index) => line.remove ? [] : [
                <></>,
                <Input disabled={editor.data.studios.length} value={line.name} onChange={(value) => { updateShowLine(index, {name: value}); }}/>,
                <Input disabled={editor.data.studios.length} value={line.extension} onChange={(value) => { updateShowLine(index, {extension: value}); }}/>,
                <Select disabled={editor.data.studios.length} value={line.server} onChange={(value) => { updateShowLine(index, {server: value}); }}>
                  {editor.data.servers.map(({server}) => <option value={server}>{server}</option>)}
                </Select>,
                <Select disabled={editor.data.studios.length} value={line.fader} onChange={(value) => { updateShowLine(index, {fader: value}); }}>
                  {editor.data.faders.map(({id, name}) => <option value={id}>{name}</option>)}
                </Select>,
                <Toggle disabled={editor.data.studios.length} checked={line.busyall} onChange={(value) => {updateShowLine(index, {busyall: value});}}/>,
                <Select disabled={editor.data.studios.length} value={line.ringtone} onChange={(value) => { updateShowLine(index, {ringtone: value}); }}>
                  {editor.data.ringtones.map(({id, name}) => <option value={id}>{name}</option>)}
                </Select>,
                ( !editor.data.studios.length ? <Button onClick={ async () => { if (await Confirm('Delete extension "' + line.extension + '"?', Warning)) deleteShowLine(index); }}>Delete</Button> : <></> ),
            ]).concat([[
              <></>,
              <></>,
              <></>,
              <></>,
              <></>,
              <></>,
              <></>,
              <Button color="blue" disabled={editor.data.studios.length} onClick={() => { addShowLine(); }}>Add</Button>
            ]])} />
            <div className="btn-row">
              <Button color="blue" disabled={editor.data.studios.length} type="submit">Save</Button>
            </div>
          </form>
        </Panel>
      </Row>
    </>);
  }

  function RenderAddressbook() {
    const [loading, setLoading] = useState(false);
    const [entries, setEntries] = useState([]);
  
    function refreshEntries() {
      RequestAPI(apiUrl, null, null, data => {
        setLoading(false);
        setEntries(data);
      });
    }
  
    useEffect(() => {
      setLoading(true);
      refreshEntries();
    }, []);
  
    return (<>
      <LoadIndicator open={loading}/>
      <Breadcrumb item="Addressbook" path={[
        { link: '/shows', text: 'Shows Configuration' }
      ]}/>
      <Warnings value={warnings}/>
  
      <h1>Shows Address Books</h1>
      <br/>
  
      <Row spacing={sitePadding}>        
        <Panel title="Shows">
          <Table
            alignLabelsLeft={true}
            headers={[
              <>Show Name<InfoTooltip source={helpContext} path="shows/addressbooks-show"/></>,
              <>Address Book Entries<InfoTooltip source={helpContext} path="shows/addressbooks-entries"/></>,
              ""]}
            rows={entries.map((entry) => [
              entry.name,
              entry.nab,
              <Link to={'/shows/'+ addressbookUrl + '/'  + entry.id}><Button>Edit</Button></Link>
            ])} />
        </Panel>
      </Row>
    </>);
  }
  
  function RenderAddressbookEditor() {
    const Warning = { variant: 'warning' }; //Alert, Confirm
    const UnsavedMessage = 'Address book data has been changed but haven\'t been saved. Continue?';
  
    const [loading, setLoading] = useState(false);
    const [unsaved, setUnsaved] = useState(false);
    const [editor, setEditor] = useState({data: []});
    
    function editAddressBook(id) {
      RequestAPI(apiUrl, null, null, (shows) => {
        let found = false;
        shows.map((show) => {
          if (show.id === Number(id)) found = true;
          return false;
        });

        if (found) RequestAPI(apiUrl + '/' + id, null, null, (show) => {
          RequestAPI(apiUrl + '/' + id + '/' + addressbookUrl, null, null, (data) => {
            setLoading(false);
            setEditor({id: id, name: show.name, data: data});
          });
        }); else history.replace(path + '/' + addressbookUrl);
      });
    }
    function addAddressBook() {
      let ab = _clone(editor.data);
      ab.push({add: true});
      setEditor(_merge({}, editor, {data: ab}));
      setUnsaved(true);
    }
    function updateAddressBook(index, value) {
      let ab = _clone(editor.data);
      ab[index] = _merge(ab[index], value);
      ab[index].update = true;
      setEditor(_merge({}, editor, {data: ab}));
      setUnsaved(true);
    }
    function deleteAddressBook(index) {
      let ab = _clone(editor.data);
      if (ab[index].add) ab.splice(index, 1); else ab[index].remove = true;
      setEditor(_mergeArray({}, editor, {data: ab}));
      setUnsaved(true);
    }
    function saveAddressBook(e) {
      e.preventDefault();
  
      setUnsaved(false);
  
      let addEntries = editor.data.filter(entry => entry.add);
      let removeEntries = editor.data.filter(entry => entry.remove);
      let updateEntries = editor.data.filter(entry => !entry.add && entry.update);
  
      let id = editor.id;
      let next = () => save(editor.id, addEntries, removeEntries);
      let save = (id, addEntries, removeEntries) => {
        if (addEntries.length) {
          let entry = addEntries.shift();
          if (!entry.name && entry.key) entry.name = entry.key;
          if (!entry.key && entry.name) entry.key = entry.name;
          RequestAPI(apiUrl + '/' + id + '/' + addressbookUrl, entry, RequestMethods.POST, () => { next(); });
        } else if (removeEntries.length) {
          let entry = removeEntries.shift();
          RequestAPI(apiUrl + '/' + id + '/' + addressbookUrl + '/' + entry.id, null, RequestMethods.DELETE, () => { next(); });
        } else if (updateEntries.length) {
          let entry = updateEntries.shift();
          RequestAPI(apiUrl + '/' + id + '/' + addressbookUrl + '/' + entry.id, entry, RequestMethods.POST, () => { next(); });
        } else editAddressBook(id);
      }
  
      setLoading(true);
      save(id, addEntries, removeEntries);
    }
  
    let { id } = useParams();
    useEffect(() => {
      setLoading(true);
      editAddressBook(id);
    }, [id]);

    return (<>
      <LoadIndicator open={loading}/>
      <Breadcrumb item="Entries" path={[
        { link: '/shows', text: 'Shows Configuration' },
        { link: '/shows/' + addressbookUrl, text: 'Addressbook' }
      ]}/>
      <Warnings value={warnings}/>
  
      <h1>Show "{editor.name}" Configuration</h1>
      <br/>
  
      <Row spacing={sitePadding}>
        <Panel title="Address Book">
          <Prompt when={unsaved} message={UnsavedMessage}/>
  
          <form onSubmit={saveAddressBook}>
            <Table
              alignLabelsLeft={true}
              headers={[
                <>Name<InfoTooltip source={helpContext} path="shows/addressbook-name"/></>,
                <>Number / SIP Address<InfoTooltip source={helpContext} path="shows/addressbook-address"/></>,
                ""]}
              rows={editor.data.map((ab, index) => ab.remove ? [] : [
                <Input value={ab.name} autoFocus={index === 0} onChange={(value) => { updateAddressBook(index, {name: value}); }}/>,
                <Input value={ab.key} onChange={(value) => { updateAddressBook(index, {key: value}); }}/>,
                <Button onClick={ async () => { if (await Confirm('Delete entry?', Warning)) deleteAddressBook(index); }}>Delete</Button>
              ]).concat([[
                <></>,
                <></>,
                <Button color="blue" onClick={() => { addAddressBook(); }}>Add</Button>
              ]])}/>
            <div className="btn-row">
              <Button color="blue" type="submit">Save</Button>
            </div>
          </form>
        </Panel>
      </Row>
    </>);
  }
  
  return (<Switch>
    <Route exact path={path}>
      <RenderShows/>
    </Route>
    <Route exact path={`${path}/${addressbookUrl}`}>
      <RenderAddressbook/>
    </Route>
    <Route path={`${path}/${addressbookUrl}/:id`}>
      <RenderAddressbookEditor/>
    </Route>
    <Route path={`${path}/:id`}>
      <RenderShowEditor/>
    </Route>
  </Switch>);
}

export { ShowsPage };