import { appInit, render } from "@telosalliance/ui-core-framework";
import App from "./App";
import "./app.css";

appInit(App);

if (module.hot) {
  module.hot.accept("./App", () => {
    const NextApp = require("./App").default;
    // console.groupEnd();
    // console.group("%c------------ Reload ------------", "color: #375");
    console.clear();

    render(NextApp);
  });
}
