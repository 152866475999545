import React from "react";
import Popover from "react-popover";
import FocusLock from "react-focus-lock";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Table, Button, Input, Select } from "@telosalliance/ui-core";
import { cloneDeep as _clone } from 'lodash';
import { IsValidIPv4MulticastP, IsValidLwChannel } from './Utils';

class StreamPicker extends React.Component {
  static defaultProps = {
    auto: false,
    max_channels: 8,
    type: "input",
    required: false,
    smpte2022_7: false,
    onChange: () => null
  };

  state = {
    isOpen: false,
    errorMessage: false,
    value: {}
  };

  inputRef = React.createRef();
  isInput = (this.props.type === "input");

  nullAddress = "0.0.0.0";

  componentDidMount() {
    const ref = this.inputRef.current;
    if (ref) {
      const inputWidth = ref.offsetWidth;
      this.setState({ inputWidth });
    }
  }

  closePopover() {
    this.setState({ isOpen: false, errorMessage: false });
  }

  handlePopoverSubmit = ev => {
    // prevent refresh
    ev.preventDefault();
    // prevent bubbling
    ev.stopPropagation();

    let update = {};
    const { value } = this.state;
    if (value.aes67) {
      if (this.props.smpte2022_7) {
        const aes67_streams = String(value.aes67_stream).trim().split(",");

        if (aes67_streams[0] && !IsValidIPv4MulticastP(aes67_streams[0]) && aes67_streams[0] !== this.nullAddress) {
          this.setState({ errorMessage: "Invalid primary AES67 multicast IP address" });
          return;
        }
  
        if (aes67_streams[1] && !IsValidIPv4MulticastP(aes67_streams[1]) && aes67_streams[1] !== this.nullAddress) {
          this.setState({ errorMessage: "Invalid secondary AES67 multicast IP address" });
          return;
        }

        update.stream = aes67_streams.map(s => {
          const v = String(s).trim();
          if (v === "") return this.nullAddress;
          else return v;
        }).slice(0, 2).join(",");

        if (update.stream === [this.nullAddress, this.nullAddress].join(",")) {
          this.setState({ errorMessage: "Invalid primary and secondary AES67 multicast IP address" });
          return;
        }
      } else {
        if (value.aes67_stream && !IsValidIPv4MulticastP(value.aes67_stream)) {
          this.setState({ errorMessage: "Invalid AES67 multicast IP address" });
          return;
        }

        update.stream = value.aes67_stream;
      }

      if (this.isInput) {
        update.num_channels = value.aes67_num_channels;
        update.use_channel = value.aes67_use_channel;
      }
    } else {
      if (this.props.smpte2022_7) {
        const lw_streams = String(value.lw_stream).trim().split(",");

        if (lw_streams[0] && (!IsValidLwChannel(lw_streams[0]) || (Number(lw_streams[0]) < 0))) {
          this.setState({ errorMessage: "Invalid primary Livewire channel address" });
          return;
        }

        if (lw_streams[1] && (!IsValidLwChannel(lw_streams[1]) || (Number(lw_streams[1]) < 0))) {
          this.setState({ errorMessage: "Invalid secondary Livewire channel address" });
          return;
        }

        update.stream = lw_streams.map(s => {
          const v = String(s).trim();
          if (v === "") return "0";
          else return Number(v) * (value.lw_source === "true" ? 1 : -1);
        }).slice(0, 2).join(",");

        if (update.stream === "0,0") {
          this.setState({ errorMessage: "Invalid primary and secondary Livewire channel address" });
          return;
        }
      } else {
        if (value.lw_stream && (!IsValidLwChannel(value.lw_stream) || (Number(value.lw_stream) < 0))) {
          this.setState({ errorMessage: "Invalid Livewire channel address" });
          return;
        }

        update.stream = value.lw_stream ? Number(value.lw_stream) * (value.lw_source === "true" ? 1 : -1) : "";
      }

      if (this.isInput) {
        update.num_channels = value.lw_num_channels;
        update.use_channel = value.lw_use_channel;
      }
    }
    this.props.onChange(update);
    this.setState({ isOpen: false, errorMessage: false });
  };

  loadValue = () => {
    let value = _clone(this.props.value);

    //defaults
    value.aes67_stream = "";
    value.lw_stream = "";
    value.lw_source = "true";

    let streams = String(value.stream).split(",");
    if (IsValidIPv4MulticastP(streams[0]) || streams[0] === this.nullAddress) {
      value.aes67 = true;
      value.aes67_stream = value.stream;
    } else {
      let stream = streams[0] ? Math.abs(Number(streams[0])) : "";
      if (this.props.smpte2022_7 && streams.length > 1) stream += "," + Math.abs(Number(streams[1]));
      value.aes67 = false;
      value.lw_stream = stream ? stream : "";
      if (streams[0]) value.lw_source = String(Number(streams[0]) >= 0);
    }

    if (this.isInput) {
      //defaults
      value.aes67_num_channels = 8;
      value.aes67_use_channel = 1;
      value.lw_num_channels = 2;
      value.lw_use_channel = 1;

      if (value.aes67) {
        if (value.num_channels) value.aes67_num_channels = value.num_channels;
        if (value.use_channel) value.aes67_use_channel = value.use_channel;
      } else {
        if (value.use_channel) value.lw_use_channel = value.use_channel;
      }
    }

    return value;
  }

  setValue = (key, data) => {
    let value = _clone(this.state.value);

    switch (key) {
      case "aes67_num_channels":
        value[key] = Number(data);
        if (!value.aes67_use_channel) value.aes67_use_channel = 1;
        else if (Number(value.aes67_use_channel) > Number(value.aes67_num_channels)) value.aes67_use_channel = 1;
        break;
      case "lw_use_channel":
      case "aes67_use_channel":
        value[key] = Number(data);
        break;
      default:
        value[key] = data;
        break;
    }

    this.setState({ value });
  }

  render() {
    const { className, value } = this.props;

    let channels = [];
    for (let i = 1; i <= this.props.max_channels; i++) channels.push(i);

    let displayValue = "";
    if (this.props.auto) {
      displayValue = "auto";
    } else if (value.stream) {
      displayValue = value.stream + "";
      if (value.num_channels && this.isInput) {
        displayValue += ' / ' + value.num_channels;
        if (value.use_channel) displayValue += ',' + value.use_channel;
      }
    }

    return (
      <Popover
        isOpen={this.state.isOpen}
        preferPlace="right"
        className={className}
        onOuterAction={() => this.closePopover()}
        body={
          <FocusLock>
            <form
              onSubmit={this.handlePopoverSubmit}
              onKeyDown={ev => {
                ev.key === "Escape" && this.closePopover()
                ev.stopPropagation();
              }}
            >
            <Table
              columnWidths={["130px", "300px"]}
              rows={[
                [<ToggleButtonGroup
                  className="uic-toggleBtns"
                  value={this.state.value.aes67}
                  exclusive={true}
                  onChange={(event, value) => { if (value !== null) this.setValue("aes67", value); }}>
                  <ToggleButton disableRipple value={false}>Livewire</ToggleButton>
                  <ToggleButton disableRipple value={true}>AES67 Multicast</ToggleButton>
                </ToggleButtonGroup>],
              ].concat(
                this.state.value.aes67
                  ? [["Address", <Input autoFocus value={this.state.value.aes67_stream} onChange={(value) => { this.setValue("aes67_stream", value); }}/>]].concat(!this.isInput ? [] :
                    [
                      ["Channels", <Select value={this.state.value.aes67_num_channels} onChange={(value) => { this.setValue("aes67_num_channels", value); }} options={channels}/>],
                      ["Use channel", <Select value={this.state.value.aes67_use_channel} onChange={(value) => { this.setValue("aes67_use_channel", value); }} options={channels.filter(ch => ch <= this.state.value.aes67_num_channels)}/>]
                    ])
                  : [["Address", <>
                      <Input autoFocus className="lw_stream" value={this.state.value.lw_stream} onChange={(value) => { this.setValue("lw_stream", value); }}/>
                      <span className="lw_source"><Select value={this.state.value.lw_source} onChange={(value) => { this.setValue("lw_source", value); }}>
                        {[{id: "true", title: "From Source"}, {id: "false", title: "To Source"}].map(({id, title}) => <option value={id}>{title}</option>)}
                      </Select></span>
                    </>]].concat(!this.isInput ? [] :
                    [
                      ["Channels", <Select disabled={true} value={this.state.value.lw_num_channels} onChange={(value) => { /*this.setValue("lw_num_channels", value);*/ }} options={channels.filter(ch => ch <= 2)}/>],
                      ["Use channel", <Select value={this.state.value.lw_use_channel} onChange={(value) => { this.setValue("lw_use_channel", value); }} options={channels.filter(ch => ch <= this.state.value.lw_num_channels)}/>]
                    ])
              ).concat([
                this.state.errorMessage ? [<span className="errorMessage">{this.state.errorMessage}</span>] : [],
                [null,
                  <>
                    <Button color="blue" type="submit">Set</Button>
                    <Button onClick={() => { this.closePopover(); }}>Cancel</Button>
                  </>]
              ])}/>
            </form>
          </FocusLock>
        }
        children={
          <input
            ref={this.inputRef}
            className={`uic-input ${ this.state.isOpen ? " popoverInput-inactive" : "" }`}
            style={this.props.style}
            size={this.props.size}
            readOnly
            disabled={this.props.disabled || this.props.auto}
            value={displayValue}
            onFocus={ev => {
              this.setState(state =>
                state.isOpen
                  ? { isOpen: false }
                  : { isOpen: true, value: this.loadValue() }
              );
            }}
          />
        }
      />
    );
  }
}

export { StreamPicker };
