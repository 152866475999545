import React, { useState, useEffect } from "react";
import { Row, Panel, Table, Button, Select, Toggle } from "@telosalliance/ui-core";
import { confirm as Confirm, useSessionStorage } from "@telosalliance/ui-core-framework";
import { RequestAPI, RequestMethods, Breadcrumb, Warnings, LoadIndicator } from '../Utils';
import { Prompt, Switch, Route, useRouteMatch, useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import Popover from "react-popover";

const HelpEditorPage = ({sitePadding, warnings, helpContext, setHelpContext}) => {
  const Warning = { variant: 'warning' }; //Alert, Confirm
  
  const textAreaStyle = {height: '300px', width: '60%', fontFamily: 'monospace'};

  const [loading, setLoading] = useState(false);

  const { path } = useRouteMatch();

  function RenderHelpEditor(props) {
    const [editor, setEditor] = useState({ unsaved: false });
    const [addItem, setAddItem] = useState([]);
    const [helpEditorEnabled, setHelpEditorEnabled] = useSessionStorage('helpEditorEnabled', false);

    function saveItem() {
      setEditor({...editor, ...{ unsaved: false }});
      setLoading(true);
      let updateHelpContext = {...helpContext, ...{ [editor.item]: editor.value }};
      RequestAPI('/help/context', updateHelpContext, RequestMethods.POST, () => {
        setLoading(false);
        setHelpContext(updateHelpContext);
      });
    }

    function editItem(item, save = false) {
      if (save) saveItem();
      setEditor({ unsaved: false, popover: true, item: item, value: helpContext[item] ? helpContext[item] : '' });
    }

    let { page, id } = useParams();
    useEffect(() => {
      if (page && id) {
        let path = [page, id].join('/');
        if (Object.keys(helpContext).includes(path)) editItem(path);
        else {
          setAddItem([path]);
          editItem(path);
        }
      }
    }, []);

    return (
      <>
        <LoadIndicator open={loading}/>
        <Breadcrumb item="Help Context Editor"/>
        <Warnings value={warnings}/>

        <h1>Help Context</h1>
        <br/>

        <Prompt when={editor.unsaved} message={"The updated item hasn't been saved! Continue?"}/>
        <Row spacing={sitePadding}>
          <Panel title="Tooltip">
            <Table
              style={{minWidth: "100%"}}
              alignLabelsLeft={true}
              rows={[
                [<>Show missing help tags: <Toggle checked={helpEditorEnabled} onChange={(value) => setHelpEditorEnabled(value)}/></>],
                [<Select value={editor.item} onChange={async (value) => {
                  if (editor.unsaved) editItem(value, await Confirm('Item has been updated. Save first?', Warning))
                  else editItem(value);
                }} style={{width: '100%'}} options={[''].concat(addItem).concat(Object.keys(helpContext)).sort()}/>],
                [
                  <>
                    <Popover
                      isOpen={editor.item ? editor.popover : false}
                      preferPlace="right"
                      className="uic-infoTooltip"
                      body={<div onClick={() => setEditor({...editor, ...{popover: false}})}><ReactMarkdown source={editor.value}/></div>}
                      children={
                        <textarea disabled={!editor.item} className="uic-input" style={textAreaStyle} value={editor.value} onChange={(ev) => {
                          setEditor({...editor, ...{unsaved: true, popover: true, value: ev.currentTarget.value}});
                        }}/>
                      }
                    />
                  </>
                ]
              ]}/>

            <div className="btn-row">
              <Button color="blue" disabled={editor.item ? false : true} onClick={() => saveItem()}>Save</Button>
            </div>
          </Panel>
          <Panel title="JSON">
            <textarea className="uic-input" style={{...textAreaStyle, ...{width: '100%', height: '150px'}}} value={JSON.stringify(helpContext, null, 1)}/>
          </Panel>
        </Row>
      </>
    );
  };

  return (
    <Switch>
      <Route exact path={path}>
        <RenderHelpEditor/>
      </Route>
      <Route path={`${path}/:page/:id`}>
        <RenderHelpEditor/>
      </Route>
    </Switch>
  );
}


export { HelpEditorPage };
