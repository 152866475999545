import React, { useState, useEffect } from "react";
import { Prompt, Route, Switch, Link, useParams, useRouteMatch } from "react-router-dom";
import { Row, Panel, Table, Input, Toggle, Select, Button, IconButton } from "@telosalliance/ui-core";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { alert as Alert, confirm as Confirm } from "@telosalliance/ui-core-framework"
import { RequestAPI, RequestMethods, Breadcrumb, LoadIndicator, Notification, Warnings, InfoTooltip, _mergeArray } from '../Utils';
import { merge as _merge, cloneDeep as _clone } from 'lodash';

const apiUrl = '/sip';

const SIPPage = ({ sitePadding, history, helpContext, warnings }) => {
  const Warning = { variant: 'warning' }; //Alert, Confirm
  const UnsavedMessage = 'SIP server data has been changed but haven\'t been saved. Continue?';

  const addPath = 'add-sip-server';
  const { path } = useRouteMatch();

  function getSIPServerName(name) { return name.server ? name.server : name.name; }

  function RenderSIPServers() {
    const [loading, setLoading] = useState(false);
    const [sip, setSIP] = useState({lookups: false, servers: []});
    const [sipUnsaved, setSIPUnsaved] = useState(false);
    const [networkInterfaces, setNetworkInterfaces] = useState([]);
    const [restartRequired, setRestartRequired] = useState(false);

    function refreshSIP() {
      setLoading(true);
      RequestAPI('/system/interfaces', null, null, (interfaces) => {
        setNetworkInterfaces(interfaces);
        RequestAPI(apiUrl, null, null, data => {
          let ifc = interfaces.length ? interfaces[0].addr : null;
          if (data.bind_addr) {
            let m = interfaces.filter((i) => i.addr === data.bind_addr);
            if (m.length) ifc = m[0].addr;
          }
          data.bind_addr = ifc;
  
          setLoading(false);
          setSIP(data);
        });
      });
    }

    function updateSIP(value) {
      setSIP(_merge({}, sip, value));
      setSIPUnsaved(true);
    }

    function saveSIP() {
      setSIPUnsaved(false);
      setRestartRequired(false);

      setLoading(true);
      RequestAPI(apiUrl, sip, RequestMethods.POST, refreshSIP);
    }
    function deleteSIPServer(name) {
      setLoading(true);
      RequestAPI(apiUrl + '/' + name, null, RequestMethods.DELETE, refreshSIP);
    }
    
    useEffect(() => { refreshSIP(); }, []);

    return (<>
      <LoadIndicator open={loading}/>
      <Breadcrumb item="SIP Configuration"/>
      <Warnings value={warnings}/>

      <h1>SIP Configuration</h1>
      <br />

      <Row spacing={sitePadding}>
        <Panel title={"General Settings"}>
          <Prompt when={sipUnsaved} message="SIP settings have been changed but haven't been saved. Continue?"/>
  
          <form onSubmit={async (e) => {
            e.preventDefault();
            
            if (restartRequired) {
              if (await Confirm("A manual VXs restart might be required to change the port or network interface. Continue?", Warning)) saveSIP();
            } else saveSIP();
          }}>
            <Table
              className="settings"
              rows={[
                ["Use SRV lookups", <><Toggle checked={sip.lookups} onChange={(value) => updateSIP({lookups: value})} /><InfoTooltip source={helpContext} path="sip/use-srv-lookups"/></>],
                ["Port", <><input className="uic-input" value={sip.bind_port} type="number" min="1" max="65535" onChange={(e) => { setRestartRequired(true); updateSIP({bind_port: Number(e.currentTarget.value)}); }}/><InfoTooltip source={helpContext} path="sip/port"/></>],
                ["Network interface", <><Select value={sip.bind_addr} style={{width: 'auto'}} onChange={(value) => { setRestartRequired(true); updateSIP({bind_addr: value}); }}>{networkInterfaces.map((ifc) => <option value={ifc.addr}>{ifc.name} ({ifc.cidr}, {ifc.mac})</option>)}</Select><InfoTooltip source={helpContext} path="sip/network-interface"/></>]
              ]}/>
              <div className="btn-row">
                <Button color="blue" type="submit">Save</Button>
              </div>
            </form>
        </Panel>
      </Row>
      { sip.servers ?
        <Row spacing={sitePadding}>        
          <Panel title="Servers">
            <Table
              className="servers"
              alignLabelsLeft={true}
              headers={[
                <>Name<InfoTooltip source={helpContext} path="sip/servers-name"/></>,
                <>Server<InfoTooltip source={helpContext} path="sip/servers-server"/></>,
                <>Lines<InfoTooltip source={helpContext} path="sip/servers-lines"/></>,
                ""]}
              rows={sip.servers && sip.servers.map((server) => [
                server.name,
                server.server,
                server.lines,
                <>
                  <Link to={path + '/' + getSIPServerName(server)}><Button>Edit</Button>&nbsp;</Link>
                  <Button onClick={ async () => {
                    const name = getSIPServerName(server);
                    if (await Confirm('Deleting "' + name + '" will remove it from all shows! Are you sure you want to continue?', Warning)) deleteSIPServer(name);
                  }}>Delete</Button>
                </>
              ]).concat([[
                <></>,
                <></>,
                <></>,
                <Link to={path + '/' + addPath}><Button color="blue">Add</Button></Link>
              ]])} />
          </Panel>
        </Row>
      : <></>}
    </>);
  }

  function RenderSIPServerEditor() {
    const [loading, setLoading] = useState(false);
    const [unsaved, setUnsaved] = useState(false);
    const [editor, setEditor] = useState({add: false, data: { lines: [], inuse: [] }});
  
    function editSIPServer(name) {
      setLoading(true);
      if (name === addPath) {
        setLoading(false);
        setEditor({add: true, data: { proxy: { type: 'loose' }, lines: [], inuse: [] }});
      } else {
        RequestAPI(apiUrl, null, null, sip => {
          let found = false;
          if (sip.servers) sip.servers.map((server) => {
            if (name === getSIPServerName(server)) found = true;
            return false;
          });

          if (found) RequestAPI(apiUrl + '/' + name, null, null, (data) => {
            setLoading(false);
            data.lines.map((line) => {
              line.studios = [...new Set(line.shows.reduce((a, show) => {
                return a.concat(show.studios.map((studio) => studio.name));
              }, []))];
              return null;
            });
            setEditor({data: data});
          }); else history.replace(path);
        });
      }
    }
    function updateSIPServer(value) {
      if (value.proxy && value.proxy.type && editor.data.proxy && !editor.data.proxy.ip) {
        Alert('Please enter "Outbound Proxy" first!', Warning);
        return;
      }
  
      setEditor(_merge({}, editor, { data: value }));
      setUnsaved(true);
    }
    function saveSIPServer(e) {
      e.preventDefault();
  
      if (editor.data.inuse.length) {
        saveSIPServerLines();
        return;
      }
  
      if (!editor.data.server) {
        Alert('Please enter "SIP Server"!', Warning);
        return;
      }

      if (!editor.data.name) editor.data.name = editor.data.server;
      editor.data.server = editor.data.server.replace(/\//g, '-');
      editor.data.name = editor.data.name.replace(/\//g, '-');

      let name = getSIPServerName(editor.data);
      if (name === addPath) {
        Alert('SIP Server name "' + name + '" cannot be used! Please choose a different name.', Warning);
        return;
      }
      
      setUnsaved(false);
  
      setLoading(true);
      RequestAPI(apiUrl + '/' + name, editor.data, RequestMethods.POST, () => { saveSIPServerLines(() => {
        if (editor.add) history.replace(path + '/' + name); else editSIPServer(name);
      }); });
    }
    
    function addSIPServerLine() {
      let lines = _clone(editor.data.lines);
      lines.push({add: true, shows: [], studios: []});
      setEditor(_merge({}, editor, { data: { lines: lines }}));
      setUnsaved(true);
    }
    function updateSIPServerLine(index, value, unsaved = true) {
      let lines = _clone(editor.data.lines);
      lines[index] = _merge(lines[index], { ...value, ...{ updated: true }});
      setEditor(_merge({}, editor, { data: { lines: lines }}));
      setUnsaved(unsaved);
    }
    function saveSIPServerLines(callback = null) {
      let addLines = editor.data.lines.filter(line => line.add);
      let storeLines = editor.data.lines.filter(line => !line.add && !line.remove && line.updated);
      let removeLines = editor.data.lines.filter(line => line.remove);
  
      let name = getSIPServerName(editor.data);
      let next = () => save(name, storeLines, removeLines, addLines);
      let save = (name, storeLines, removeLines, addLines) => {
        if (storeLines.length) {
          let line = storeLines.shift();
          RequestAPI(apiUrl + '/' + name + '/lines/' + line.extension, line, RequestMethods.POST, () => { next(); });
        } else if (removeLines.length) {
          let line = removeLines.shift();
          RequestAPI(apiUrl + '/' + name + '/lines/' + line.extension, null, RequestMethods.DELETE, () => { next(); });
        } else if (addLines.length) {
          let line = addLines.shift();
          if (!line.extension) next();
          RequestAPI(apiUrl + '/' + name + '/lines', line, RequestMethods.POST, () => { next(); });
        } else if (callback) callback(); else editSIPServer(name);
      }
  
      save(name, storeLines, removeLines, addLines);
    }
    function deleteSIPServerLine(index) {
      let lines = _clone(editor.data.lines);
      if (lines[index].add) lines.splice(index, 1); else lines[index].remove = true;
      setEditor(_mergeArray({}, editor, { data: { lines: lines}}));
    }  
    
    let { id } = useParams();
    useEffect(() => { editSIPServer(id); }, [id]);

    return (<>
      <LoadIndicator open={loading}/>
      <Breadcrumb item="Server" path={[
        { link: '/sip', text: 'SIP Configuration' }
      ]}/>
      <Warnings value={warnings}/>

      <h1>{editor.add !== true ? <>SIP Server "{getSIPServerName(editor.data)}"</> : <>Add SIP Server</>}</h1>
      <br />
      <Row spacing={sitePadding}>
        <Panel title="General Settings" className="general">
          <form onSubmit={saveSIPServer}>
            <Notification visible={editor.data.inuse.length}>
              Server is in use by studio(s) "{[...new Set(editor.data.inuse.map(el => el.studio.name))].join(', ')}
              " (show(s) "{[...new Set(editor.data.inuse.map(el => el.show.name))].join(', ')}")
              <InfoTooltip source={helpContext} path="sip/in-use"/>
            </Notification>
            <Prompt when={unsaved} message={UnsavedMessage}/>

            <Table
              className="server"
              rows={[
                ["SIP Server", <><Input autoFocus disabled={!editor.add || editor.data.inuse.length} value={editor.data.server} onChange={(value) => { updateSIPServer({server: value}); }}/><InfoTooltip source={helpContext} path="sip/server"/></>],
                ["Name", <><Input disabled={editor.data.inuse.length} value={editor.data.name} onChange={(value) => { updateSIPServer({name: value}); }}/><InfoTooltip source={helpContext} path="sip/name"/></>],
                ["Outbound Proxy", <><Input disabled={editor.data.inuse.length} value={editor.data.proxy ? editor.data.proxy.ip : ''} onChange={(value) => { updateSIPServer({proxy: {ip: value}}); }}/><InfoTooltip source={helpContext} path="sip/outbound-proxy"/></>],
                ["Outbound Proxy Type",
                  <>
                    <Select disabled={editor.data.inuse.length} value={editor.data.proxy ? editor.data.proxy.type : ''} onChange={(value) => { updateSIPServer({proxy: {type: value}}); }}>
                      {[
                        {id: "loose", name: "Standard"},
                        {id: "strict", name: "Strict"},
                        {id: "fixed", name: "Fixed IP"}
                      ].map(({id, name}) => <option value={id}>{name}</option>)}
                    </Select>
                    <InfoTooltip source={helpContext} path="sip/outbound-proxy-type"/>
                  </>
                ],
                ["External IP", <><Input disabled={editor.data.inuse.length} value={editor.data.ip} onChange={(value) => { updateSIPServer({ip: value}); }}/><InfoTooltip source={helpContext} path="sip/external-ip"/></>],
                ["Local Domain", <><Input disabled={editor.data.inuse.length} value={editor.data.domain} onChange={(value) => { updateSIPServer({domain: value}); }}/><InfoTooltip source={helpContext} path="sip/local-domain"/></>],
              ]}/>
            <br/>

            <Table
              alignLabelsLeft={true}
              headers={[
                <>Extension<InfoTooltip source={helpContext} path="sip/lines-extension"/></>,
                <>Register<InfoTooltip source={helpContext} path="sip/lines-register"/></>,
                <>Expires<InfoTooltip source={helpContext} path="sip/lines-expires"/></>,
                <>Auth User<InfoTooltip source={helpContext} path="sip/lines-user"/></>,
                <>Auth Password<InfoTooltip source={helpContext} path="sip/lines-password"/></>,
                <>Shows (Studios)<InfoTooltip source={helpContext} path="sip/lines-shows-studios"/></>, ""]}
              rows={editor.data.lines.map((line, index) => line.remove ? [] : [
                ( line.add ? <Input value={line.extension} autoFocus onChange={(value) => { updateSIPServerLine(index, {extension: value}); }}/> : <>{line.extension}</> ),
                <Toggle disabled={line.studios.length}  checked={line.register} onChange={(value) => {updateSIPServerLine(index, {register: value});}}/>,
                <Input disabled={line.studios.length} value={line.expires} onChange={(value) => { updateSIPServerLine(index, {expires: value}); }}/>,
                <Input disabled={line.studios.length} value={line.user} onChange={(value) => { updateSIPServerLine(index, {user: value}); }}/>,
                <nobr>
                  <Input disabled={line.studios.length} type={line.showpassword ? "input" : "password"} value={line.password} ref={input => line.passwordref = input} onChange={(value) => { updateSIPServerLine(index, {password: value}); }}/>
                  &nbsp;<IconButton disabled={line.studios.length} icon={line.showpassword ? VisibilityIcon : VisibilityOffIcon} onClick={(value) => { updateSIPServerLine(index, {showpassword: !line.showpassword}, false); }}/>
                </nobr>,
                <nobr>
                  {line.shows && line.shows.length ? line.shows.map(show => show.name).join(', ') : <></>}
                  {line.studios && line.studios.length ? <> ({line.studios.join(', ')})</> : <></>}
                </nobr>,
                ( !line.studios.length ? <Button onClick={ async () => { if (await Confirm('Delete extension' + (line.extension ? ' "' + line.extension + '"' : '') + '?', Warning)) deleteSIPServerLine(index); }}>Delete</Button> : <></> ),
            ]).concat([[
              <></>,
              <></>,
              <></>,
              <></>,
              <></>,
              <></>,
              <Button color="blue" onClick={() => { addSIPServerLine(); }}>Add</Button>
            ]])} />
            <div className="btn-row">
              <Button color="blue" type="submit">Save</Button>
            </div>
          </form>
        </Panel>
      </Row>
    </>);
  }

  return (<Switch>
    <Route exact path={path}>
      <RenderSIPServers/>
    </Route>
    <Route path={`${path}/:id`}>
      <RenderSIPServerEditor/>
    </Route>
  </Switch>);
};

export { SIPPage };
