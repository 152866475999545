import React, { useState, useEffect, useRef } from "react";
import { Redirect, Prompt } from "react-router-dom";
import { Row, Panel, CollapsablePanel, Table, Button, Input, IconButton, Modal, Select, Toggle } from "@telosalliance/ui-core";
import { cloneDeep as _clone, merge as _merge } from 'lodash';
import { confirm as Confirm } from "@telosalliance/ui-core-framework";
import { RequestAPI, RequestMethods, LoginState, Breadcrumb, Warnings, LoadIndicator, InfoTooltip } from '../Utils';
import LinearProgress from "@material-ui/core/LinearProgress";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const rebootSeconds = 10;

const MainPage = ({ sitePadding, helpContext, warnings }) => {
  const Warning = { variant: 'warning' }; //Alert, Confirm

  const [rebootCounter, setRebootCounter] = useState(null);
  const rebootCounterRef = useRef(rebootCounter);
  rebootCounterRef.current = rebootCounter;

  const [loading, setLoading] = useState(false);
  const [networkInterfaceUnsaved, setNetworkInterfaceUnsaved] = useState(false);
  const [networkInterfaces, setNetworkInterfaces] = useState([]);
  const [networkInterfaceA, setNetworkInterfaceA] = useState('');
  const [networkInterfaceB, setNetworkInterfaceB] = useState('');
  const [networkInterfaceBdisabled, setNetworkInterfaceBdisabled] = useState(false);
  const qosTypes = {livewire: {}, standard: {}, aes67: {}};
  const qosOptions = ['eth_prio', 'eth_vlan', 'ip_ttl', 'ip_dontfrag'];
  const [qosUnsaved, setQosUnsaved] = useState(false);
  const [qos, setQos] = useState(qosTypes);
  const [lwcpPassword, setLwcpPassword] = useState({});
  //const [webPassword, setWebPassword] = useState({});

  function refreshMain() {
    const passwords = {password: ''};
    //setWebPassword(passwords);
    setLwcpPassword(passwords);

    RequestAPI('/system/interfaces', null, null, (interfaces) => {
      setLoading(false);
      setNetworkInterfaces(interfaces);
      RequestAPI('/config/audioio', null, null, (audioio) => {
        let getIfc = (system, audioio, idx) => {
          if (Array.isArray(system) && (audioio.length >= idx + 1)) {
            let m = system.filter((i) => i.addr === audioio[idx]);
            if (m.length) return m[0].addr;
          }
          return '';
        }

        let ifcA = getIfc(interfaces, audioio.interfaces, 0);
        setNetworkInterfaceA(ifcA);

        if (ifcA === '') {
          setNetworkInterfaceB('');
          setNetworkInterfaceBdisabled(true);
        } else {
          setNetworkInterfaceB(getIfc(interfaces, audioio.interfaces, 1));
          setNetworkInterfaceBdisabled(false);
        }

        RequestAPI('/config/qos', null, null, (qos) => {
          Object.keys(qosTypes).map((key) => {
            qosOptions.map((opt) => {
              qos[key][opt + '_set'] = opt in qos[key];
              return null;
            });
            return null;
          });

          setQos(qos);
        });
      });
    });
  }

  function updateNetworkInterfaceA(value) {
    setNetworkInterfaceA(value);
    setNetworkInterfaceUnsaved(true);

    if (value === '') {
      setNetworkInterfaceB('');
      setNetworkInterfaceBdisabled(true);
    } else {
      setNetworkInterfaceBdisabled(false);
    }
}
  function updateNetworkInterfaceB(value) {
    setNetworkInterfaceB(value);
    setNetworkInterfaceUnsaved(true);
  }
  function updateQos(value) {
    setQos(_merge({}, qos, value));
    setQosUnsaved(true);
  }
  //function updateWebPassword(value) { setWebPassword(_merge({}, webPassword, value)); }
  function updateLwcpPassword(value) { setLwcpPassword(_merge({}, lwcpPassword, value)); }
  
  function saveNetworkInterfaces() {
    setNetworkInterfaceUnsaved(false);

    setLoading(true);

    let interfaces = [];
    if (networkInterfaceA !== '') interfaces.push(networkInterfaceA);
    if (networkInterfaceB !== '') interfaces.push(networkInterfaceB);

    RequestAPI('/config/audioio', { interfaces: interfaces }, RequestMethods.POST, (result) => {
      setLoading(false);
      refreshMain();
    });
  }
  function saveQos(e) {
    e.preventDefault();

    let data = _clone(qos);
    let update = {};
    Object.keys(qosTypes).map((key) => {
      qosOptions.map((opt) => {
        if (!data[key][opt + '_set']) {
          if (!(key in update)) update[key] = {};
          update[key][opt] = '';

          delete data[key][opt];
        }
        delete data[key][opt + '_set'];
        return null;
      });
      return null;
    });

    // blank out the disabled fields
    updateQos(update);

    setQosUnsaved(false);

    setLoading(true);
    RequestAPI('/config/qos', data, RequestMethods.POST, () => {
      setLoading(false);
      refreshMain();
    });
  }
  /*function saveWebPassword() {
    setLoading(true);
    RequestAPI('/system/password/web', { password: webPassword.password }, RequestMethods.POST, (result) => {
      setLoading(false);
      refreshMain();
    });
  }*/
  function saveLwcpPassword() {
    setLoading(true);
    RequestAPI('/system/password/lwcp', { password: lwcpPassword.password }, RequestMethods.POST, (result) => {
      setLoading(false);
      refreshMain();
    });
  }

  let interval = null;
  function setRebootTimer(seconds = null) {
    if (interval) clearInterval(interval);
    if (seconds) {
      setRebootCounter(seconds)
      interval = setInterval(rebootTimer, 1000);
    } else setRebootCounter(null);
  }

  function rebootTimer() {
    setRebootCounter(rebootCounterRef.current - 1)
  }

  function renderRebootProgress() {
    return (
      rebootCounter !== null && (
        <Modal open={true}>
          <div>Restarting VXs, please wait ({rebootCounter}s)...</div>
          <br />
          <LinearProgress />
        </Modal>
      )
    );
  }

  function restartSystem() {
    RequestAPI('/system/restart', null, RequestMethods.POST, (result) => {
      setRebootTimer(rebootSeconds);
    });
  }

  useEffect(() => {
    LoginState(() => {
      setLoading(true);
      refreshMain();
    });
  }, []);

  return (
    <>
      {renderRebootProgress()}
      {rebootCounter !== null && rebootCounter <= 0 && <Redirect to="/"/>}

      <LoadIndicator open={loading}/>
      <Breadcrumb item="General Configuration"/>
      <Warnings value={warnings}/>

      <h1>General Configuration</h1>
      <br/>

      <Row spacing={sitePadding}>
        {<Panel title="System Control">
          <Button color="blue" style={{width: '300px'}} onClick={async () => {if (await Confirm('Restarting system will drop all calls and reset all connections. Continue?', Warning)) restartSystem();}}>Restart VXs</Button>
        </Panel>}

        <CollapsablePanel title="Network Interface" startCollapsed="true">
          <form onSubmit={async (e) => { e.preventDefault(); if (await Confirm("This will change the network interfaces. A manual VXs restart might be required to finish the operation. Continue?", Warning)) saveNetworkInterfaces(); }}>
            <Prompt when={networkInterfaceUnsaved} message="Network interfaces have been changed but haven't been saved. Continue?"/>
            <div className="notification-grey" style={{padding: "5px"}}>Setting network interface B enables SMPTE 2022-7 on both network interfaces!</div>

            <Table
              rows={[
                ["Interface A", <><Select value={networkInterfaceA} style={{width: 'auto'}} onChange={(value) => updateNetworkInterfaceA(value)}>
                  <option value="">&lt;not set&gt;</option>
                  {networkInterfaces.map((ifc) => <option value={ifc.addr} disabled={ifc.addr === networkInterfaceB}>{ifc.name} ({ifc.cidr}, {ifc.mac})</option>)}
                  </Select><InfoTooltip source={helpContext} path="main/network-interface-a"/></>],
                ["Interface B", <><Select value={networkInterfaceB} style={{width: 'auto'}} onChange={(value) => updateNetworkInterfaceB(value)} disabled={networkInterfaceBdisabled}>
                  <option value="">&lt;not set&gt;</option>
                  {networkInterfaces.map((ifc) => <option value={ifc.addr} disabled={ifc.addr === networkInterfaceA}>{ifc.name} ({ifc.cidr}, {ifc.mac})</option>)}
                  </Select><InfoTooltip source={helpContext} path="main/network-interface-b"/></>],
                ["SMTPE 2022-7", <>{networkInterfaceB !== '' ? "Enabled" : "Disabled"}</>]
              ]}/>
            <div className="btn-row">
              <Button color="blue" type="submit">Save</Button>
            </div>
          </form>
        </CollapsablePanel>

        <CollapsablePanel title="QOS" startCollapsed="true" className="qos">
            <Prompt when={qosUnsaved} message="QOS data has been changed but haven't been saved. Continue?"/>

            <form onSubmit={(e) => saveQos(e)}>
            <h3>Global</h3>
            <Table
              rows={[
                ["Default Multicast TTL", <></>, <><input className="uic-input" value={qos.multicast_ttl} type="number" min="1" max="255" onChange={(e) => updateQos({multicast_ttl: Number(e.currentTarget.value)})}/><InfoTooltip source={helpContext} path="main/qos-default-ttl"/> <i>(1 - 255)</i></>],
              ]}/>
            <br/>

            {/*<h3>Live Stereo</h3>
            <Table
              rows={[
                //["802.1p priority", <Toggle checked={qos.livewire.eth_prio_set} onChange={(value) => updateQos({livewire: {eth_prio_set: value}})}/>, <><input className="uic-input" disabled={!qos.livewire.eth_prio_set} value={qos.livewire.eth_prio} type="number" min="0" max="7" onChange={(e) => updateQos({livewire: { eth_prio: Number(e.currentTarget.value)}})}/> <i>(0 - 7)</i></>],
                //["802.1Q VLAN tag", <Toggle checked={qos.livewire.eth_vlan_set} onChange={(value) => updateQos({livewire: {eth_vlan_set: value}})}/>, <><input className="uic-input" disabled={!qos.livewire.eth_vlan_set} value={qos.livewire.eth_vlan} type="number" min="0" max="4094" onChange={(e) => updateQos({livewire: { eth_vlan: Number(e.currentTarget.value)}})}/> <i>(1 - 4094, 0 for no VLAN)</i></>],
                ["Multicast TTL", <Toggle checked={qos.livewire.ip_ttl_set} onChange={(value) => updateQos({livewire: {ip_ttl_set: value}})}/>, <><input className="uic-input" disabled={!qos.livewire.ip_ttl_set} value={qos.livewire.ip_ttl} type="number" min="1" max="255" onChange={(e) => updateQos({livewire: { ip_ttl: Number(e.currentTarget.value)}})}/><InfoTooltip source={helpContext} path="main/qos-lw-ttl"/> <i>(1 - 255)</i></>],
                ["IP DiffServ Code Point", <></>, <><input className="uic-input" value={qos.livewire.ip_dscp} type="number" min="0" max="63" onChange={(e) => updateQos({livewire: { ip_dscp: Number(e.currentTarget.value)}})}/><InfoTooltip source={helpContext} path="main/qos-lw-dscp"/> <i>(0 - 63)</i></>],
              ]}/>
            <br/>*/}

            <h3>Standard Stereo</h3>
            <Table
              rows={[
                //["802.1p priority", <Toggle checked={qos.standard.eth_prio_set} onChange={(value) => updateQos({standard: {eth_prio_set: value}})}/>, <><input className="uic-input" disabled={!qos.standard.eth_prio_set} value={qos.standard.eth_prio} type="number" min="0" max="7" onChange={(e) => updateQos({standard: { eth_prio: Number(e.currentTarget.value)}})}/> <i>(0 - 7)</i></>],
                //["802.1Q VLAN tag", <Toggle checked={qos.standard.eth_vlan_set} onChange={(value) => updateQos({standard: {eth_vlan_set: value}})}/>, <><input className="uic-input" disabled={!qos.standard.eth_vlan_set} value={qos.standard.eth_vlan} type="number" min="0" max="4094" onChange={(e) => updateQos({standard: { eth_vlan: Number(e.currentTarget.value)}})}/> <i>(1 - 4094, 0 for no VLAN)</i></>],
                ["Multicast TTL", <Toggle checked={qos.standard.ip_ttl_set} onChange={(value) => updateQos({standard: {ip_ttl_set: value}})}/>, <><input className="uic-input" disabled={!qos.standard.ip_ttl_set} value={qos.standard.ip_ttl} type="number" min="1" max="255" onChange={(e) => updateQos({standard: { ip_ttl: Number(e.currentTarget.value)}})}/><InfoTooltip source={helpContext} path="main/qos-std-ttl"/> <i>(1 - 255)</i></>],
                ["IP DiffServ Code Point", <></>, <><input className="uic-input" value={qos.standard.ip_dscp} type="number" min="0" max="63" onChange={(e) => updateQos({standard: { ip_dscp: Number(e.currentTarget.value)}})}/><InfoTooltip source={helpContext} path="main/qos-std-dscp"/> <i>(0 - 63)</i></>],
                ["IP DF bit", <Toggle checked={qos.standard.ip_dontfrag_set} onChange={(value) => updateQos({standard: {ip_dontfrag_set: value, ip_dontfrag: 'ip_dontfrag' in qos.standard ? qos.standard.ip_dontfrag : false}})}/>, <><Select disabled={!qos.standard.ip_dontfrag_set} value={qos.standard.ip_dontfrag} onChange={(value) => updateQos({standard: {ip_dontfrag: value === "true"}})}><option value="false">Unset</option><option value="true">Set</option></Select><InfoTooltip source={helpContext} path="main/qos-std-df"/></>],
              ]}/>
            <br/>

            <h3>AES67</h3>
            <Table
              rows={[
                //["802.1p priority", <Toggle checked={qos.aes67.eth_prio_set} onChange={(value) => updateQos({aes67: {eth_prio_set: value}})}/>, <><input className="uic-input" disabled={!qos.aes67.eth_prio_set} value={qos.aes67.eth_prio} type="number" min="0" max="7" onChange={(e) => updateQos({aes67: { eth_prio: Number(e.currentTarget.value)}})}/> <i>(0 - 7)</i></>],
                //["802.1Q VLAN tag", <Toggle checked={qos.aes67.eth_vlan_set} onChange={(value) => updateQos({aes67: {eth_vlan_set: value}})}/>, <><input className="uic-input" disabled={!qos.aes67.eth_vlan_set} value={qos.aes67.eth_vlan} type="number" min="0" max="4094" onChange={(e) => updateQos({aes67: { eth_vlan: Number(e.currentTarget.value)}})}/> <i>(1 - 4094, 0 for no VLAN)</i></>],
                ["Multicast TTL", <Toggle checked={qos.aes67.ip_ttl_set} onChange={(value) => updateQos({aes67: {ip_ttl_set: value}})}/>, <><input className="uic-input" disabled={!qos.aes67.ip_ttl_set} value={qos.aes67.ip_ttl} type="number" min="1" max="255" onChange={(e) => updateQos({aes67: { ip_ttl: Number(e.currentTarget.value)}})}/><InfoTooltip source={helpContext} path="main/qos-aes67-ttl"/> <i>(1 - 255)</i></>],
                ["IP DiffServ Code Point", <></>, <><input className="uic-input" value={qos.aes67.ip_dscp} type="number" min="0" max="63" onChange={(e) => updateQos({aes67: { ip_dscp: Number(e.currentTarget.value)}})}/><InfoTooltip source={helpContext} path="main/qos-aes67-dscp"/> <i>(0 - 63)</i></>],
                ["IP DF bit", <Toggle checked={qos.aes67.ip_dontfrag_set} onChange={(value) => updateQos({aes67: {ip_dontfrag_set: value, ip_dontfrag: 'ip_dontfrag' in qos.aes67 ? qos.aes67.ip_dontfrag : false}})}/>, <><Select disabled={!qos.aes67.ip_dontfrag_set} value={qos.aes67.ip_dontfrag} onChange={(value) => updateQos({aes67: {ip_dontfrag: value === "true"}})}><option value="false">Unset</option><option value="true">Set</option></Select><InfoTooltip source={helpContext} path="main/qos-aes67-df"/></>],
                //["Receive buffer size", <></>, <><input className="uic-input" value={qos.aes67.recv_buf} type="number" min="1024" max="8096" onChange={(e) => updateQos({aes67: { recv_buf: Number(e.currentTarget.value)}})}/><InfoTooltip source={helpContext} path="main/qos-aes67-buf"/> <i>(1024-8096)</i></>],
              ]}/>
            <div className="btn-row">
              <Button color="blue" type="submit">Save</Button>
            </div>
          </form>
        </CollapsablePanel>

        {/*<Panel title="Web Password">
          <form onSubmit={async (e) => { e.preventDefault(); if (await Confirm("This will change the web interface password. Continue?", Warning)) saveWebPassword(); }}>
            <Table
              rows={[
                ["New password",
                <>
                  <Input value={webPassword.password} type={webPassword.showpassword ? "input" : "password"} onChange={(value) => { updateWebPassword({password: value}); }} />
                  &nbsp;<IconButton icon={webPassword.showpassword ? VisibilityIcon : VisibilityOffIcon} onClick={(value) => { updateWebPassword({showpassword: !webPassword.showpassword}); }}/>
                </>],
              ]}/>
            <div className="btn-row">
              <Button color="blue" type="submit">Save</Button>
            </div>
          </form>
        </Panel>*/}

        <Panel title="LWCP Password">
          <form onSubmit={async (e) => { e.preventDefault(); if (await Confirm("This action will force all devices using Livewire Control Protocol (LWCP) to immediately log out of this VX including VSets, call screening software, console controllers, Pathfinder Core PRO, and custom applications. You must manually enter the new LWCP password into each LWCP device before they will log in to VX again, even if you subsequently change this VX’s LWCP password back to the default blank value. Continue?", Warning)) saveLwcpPassword(); }}>
            <Table
              rows={[
                ["New password",
                <>
                  <Input value={lwcpPassword.password} type={lwcpPassword.showpassword ? "input" : "password"} onChange={(value) => { updateLwcpPassword({password: value}); }} />
                  &nbsp;<IconButton icon={lwcpPassword.showpassword ? VisibilityIcon : VisibilityOffIcon} onClick={(value) => { updateLwcpPassword({showpassword: !lwcpPassword.showpassword}); }}/>
                </>],
              ]}/>
            <div className="btn-row">
              <Button color="blue" type="submit">Save</Button>
            </div>
          </form>
        </Panel>
      </Row>
    </>
  );
}

export { MainPage };