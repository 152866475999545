import React, { useEffect } from "react";
import { Row, Panel } from "@telosalliance/ui-core";
import { Link } from "react-router-dom";
import { LoginState, Breadcrumb, Warnings } from '../Utils';

const SystemPage = ({ sitePadding, warnings }) => {
  useEffect(() => {
    LoginState(() => {});
  }, []);

  return (
    <>
      <Breadcrumb item="System"/>
      <Warnings value={warnings}/>

      <Row spacing={sitePadding}>
      <h1>Configuration</h1>
      <Link to="/config">
        <Panel title="Main">Network configuration. Web and LWCP passwords.</Panel>
      </Link>
      <Link to="/sip">
        <Panel title="SIP">Global SIP gateway configuration.</Panel>
      </Link>
      <Link to="/studios">
        <Panel title="Studios">Studio configuration. Livewire channels. Show selection.</Panel>
      </Link>
      <Link to="/shows">
        <Panel title="Shows">Show configuration. Line configuration. Fader assignment. SIP gateway-per-line assignment.</Panel>
      </Link>
      <Link to="/hybrid">
        <Panel title="Call Audio Processing">Audio configuration. AGC. EQ. Noise gate. Ducking. Meters.</Panel>
      </Link>
      <Link to="/tones">
        <Panel title="Tones">Tone configuration. Call progress and disposition. DTMFs. Ringtones.</Panel>
      </Link>
      <Link to="/licenses">
        <Panel title="Licenses">Licenses configuration.</Panel>
      </Link>
      <Link to="/backup">
        <Panel title="Backup / Restore">Settings backup and restore. Reset to system defaults.</Panel>
      </Link>

      <br/>

      <h1>Information</h1>
      <Link to="/info/studios">
        <Panel title="Studios">Studio information. Selected show. Line state and info. Active livewire channel info.</Panel>
      </Link>
      <Link to="/info/devices">
        <Panel title="Devices">List of connected LWCP or different protocol devices.</Panel>
      </Link>
      <Link to="/info/streams">
        <Panel title="Stream Statistics">Statistics of the livewire channels. Overruns. Underruns. TSS defects.</Panel>
      </Link>
      <Link to="/info/system">
        <Panel title="System">System information. Hardware info.</Panel>
      </Link>
    </Row>
  </>);
};

export { SystemPage };
